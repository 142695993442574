import { StoreState } from '../store';

const getNotification = (state: StoreState) => state.app.notification;
const getLoadingState = (state: StoreState) => state.app.loading;
const getClientId = (state: StoreState) => state.app.clientId;
const getStoreId = (state: StoreState) => state.app.storeId;
const getSideMenuState = (state: StoreState) => state.app.showSideMenu;
const getDashboard = (state: StoreState) => state.app.dashboard;

export { getNotification, getLoadingState, getClientId, getStoreId, getSideMenuState, getDashboard }
