import * as React from "react";
import { ContextSearch } from "./ContextSearch";
import LogoutButton from "./Logout";
import { useDispatch, useSelector } from "react-redux";
import { toggleSideMenuAction, fetchDashboardDataAction } from "../actions/ApplicationActions";
import { setCurrentlyLoggedInUser } from "../actions/UserActions";
import { fetchContextListAction } from "../actions/ContextActions";
import randomColor from "randomcolor";
import { useAuth0 } from "@auth0/auth0-react";
import { getContextList } from "../selectors/ContextSelector";
import * as Sentry from "@sentry/react";
import { getDashboard, getStoreId } from "../selectors/ApplicationSelector";
import { startOfMonth, endOfDay } from "date-fns";

const Header: React.FC = () => {
    const { getAccessTokenSilently, user } = useAuth0();
    const contextList = useSelector(getContextList);
    const dashboard = useSelector(getDashboard);
    const storeId = useSelector(getStoreId);

    const dispatch = useDispatch();

    React.useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently();
                localStorage.setItem(
                    "user",
                    JSON.stringify({ ...user, avatarColor: randomColor({ luminosity: "light" }) })
                );
                dispatch(setCurrentlyLoggedInUser(user));
                if (!contextList) {
                    dispatch(fetchContextListAction());
                }
                if (!dashboard && storeId) {
                    dispatch(
                        fetchDashboardDataAction(
                            startOfMonth(new Date()),
                            endOfDay(new Date())
                        )
                    );
                }

                localStorage.setItem("token", token);

                Sentry.setUser(user);
            } catch (e) {
                console.error(e);
                Sentry.captureException(e);
            }
        })();
    }, [getAccessTokenSilently]);

    React.useEffect(() => {
        dispatch(setCurrentlyLoggedInUser(JSON.parse(localStorage.getItem("user") as string)));
    }, []);

    return (
        <div className="app-header white box-shadow">
            <div className="row">
                <div className="col-xs-6 col-sm-6 d-flex p-a-sm">
                    <a
                        className="hidden-lg-up mx-3 d-flex align-items-center"
                        onClick={() => dispatch(toggleSideMenuAction())}
                    >
                        <i className="material-icons align">&#xe5d2;</i>
                    </a>
                    <ContextSearch />
                </div>
                <div className="col-xs-6 col-sm-6 d-sm-block hidden-xs-down">
                    <div className="navbar navbar-toggleable-sm flex-row align-items-center">
                        <ul className="nav navbar-nav ml-auto flex-row">
                            <LogoutButton />
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
