import * as React from "react";
import { Whisper, Tooltip } from "rsuite";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

const originalTimeTooltip = (title: string, label: string, value: string) => (
    <Tooltip>
        <p className="text-left my-1">{title} {label}</p>
        <p className="text-left my-1">{value}</p>
    </Tooltip>
);

interface IProps {
    item: any;
    label: string;
}

const Time: React.FC<IProps> = ({ item, label }) => {
    const { t } = useTranslation();
    return (
        <>
            <span>{get(item, `time.${label}`)}</span>
            {get(item, `originalTime.${label}`) && (
                <Whisper
                    placement="top"
                    trigger="hover"
                    speaker={originalTimeTooltip(t("originalTime"), label, get(item, `originalTime.${label}`))}
                >
                    <i className="material-icons pl-1 text-danger pointer">
                        error
                    </i>
                </Whisper>
            )}
        </>
    );
};

export { Time };
