import { all, call, put, takeEvery } from 'redux-saga/effects';
import { ContextActions, setContextListAction } from '../actions/ContextActions';
import { HTTP_METHOD } from '../constants/general';
import { API_ROUTES } from '../constants/routes';
import { IHTTPResponse } from '../types/General';
import { ApiService } from './APISaga';

const ContextSaga = function* () {
    yield all([
        takeEvery(ContextActions.FETCH_CONTEXT_LIST, fetchContext),
    ])
}

const fetchContext = function* () {
    const fetchContextResponse: IHTTPResponse = yield call(
        ApiService,
        {
            method: HTTP_METHOD.GET,
            url: API_ROUTES.CLIENTS,
        }
    );

    yield put(setContextListAction(fetchContextResponse.data.results));

};

export { ContextSaga };

