import * as React from "react";
import { useTranslation } from "react-i18next";
import { ViewPDFModal } from "../modals/ViewPDFModal";
import Button from "../_styled/Button";

interface IProps {
    previewUrl: string;
}

const ViewPDFButton: React.FC<IProps & React.HTMLAttributes<HTMLButtonElement>> = ({ previewUrl, ...props }) => {
    const { t } = useTranslation();
    const [showPDFModal, setShowPDFModal] = React.useState<boolean>(false);

    return (
        <>
            <Button
                className={`btn btn-sm ml-2 btn-outline b-accent text-accent ${props.className}`}
                onClick={() => {
                    setShowPDFModal(true);
                }}
            >
                {t("viewPdf")}
            </Button>
            {showPDFModal && (
                <ViewPDFModal isOpen={showPDFModal} toggleOpenModal={setShowPDFModal} url={previewUrl} />
            )}
        </>
    );
};

export { ViewPDFButton };
