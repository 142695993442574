import * as React from "react";
import { Button, Modal, Loader, SelectPicker } from "rsuite";
import { IModalProps } from "../../types/General";
import { useForm, get } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
    addNewItemAction,
    editItemAction,
    uploadImageAction,
    setUploadedImageAction,
} from "../../actions/GenericCRUDActions";
import { GENERIC_ITEMS_METADATA, GENERIC_CRUD_ITEM } from "../../constants/general";
import Input from "../_styled/Input";
import { getLoadingState } from "../../selectors/ApplicationSelector";
import { map, set, defaultTo } from "lodash";
import { swedenCities } from "../../constants/cities";
import { useState } from "react";
import { UploadImage } from "../UploadImage";
import { getUploadedImagePath } from "../../selectors/ItemSelector";
import { useTranslation } from "react-i18next";

type IProps = {
    editableItem: any;
    itemLabel: string;
} & IModalProps;

const ManageItemModal: React.FC<IProps> = ({ itemLabel, editableItem, isOpen, toggleOpenModal }) => {
    const loading = useSelector(getLoadingState);
    const uploadedImagePath = useSelector(getUploadedImagePath);
    const dispatch = useDispatch();
    const { register, handleSubmit, errors } = useForm();
    const { t } = useTranslation();

    React.useEffect(() => {
        dispatch(setUploadedImageAction(null));
    }, [editableItem]);

    const saveItem = (item: any, event: any) => {
        if (uploadedImagePath) set(item, "imageURL", uploadedImagePath);
        if (editableItem) {
            dispatch(editItemAction(item, get(editableItem, GENERIC_ITEMS_METADATA[itemLabel].id), itemLabel));
        } else {
            dispatch(addNewItemAction(item, itemLabel));
        }
    };

    const imagePath = uploadedImagePath || editableItem?.imageURL;

    return (
        <Modal size="xs" show={isOpen} onHide={() => toggleOpenModal(!isOpen)}>
            <form onSubmit={handleSubmit(saveItem)}>
                <Modal.Header>
                    <Modal.Title>
                        {editableItem
                            ? `${t("edit")} ${GENERIC_ITEMS_METADATA[itemLabel].singularUpper}`
                            : `${t("addNew")} ${GENERIC_ITEMS_METADATA[itemLabel].singularUpper}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label>{t("name")}:</label>
                        <Input
                            type="text"
                            className="form-control"
                            placeholder={t("enterName")}
                            name="name"
                            defaultValue={editableItem?.name}
                            ref={register({ required: true })}
                        />
                        {errors && errors.name && (
                            <div className="alert alert-danger p-2 mt-1 text-xs">{t("nameRequired")}</div>
                        )}
                    </div>
                    <div className="form-group">
                        <label>{t("organizationNumber")}:</label>
                        <Input
                            type="text"
                            className="form-control"
                            placeholder={t("organizationNumber")}
                            name="organizationNumber"
                            defaultValue={editableItem?.organizationNumber}
                            ref={register({ required: true })}
                        />
                        {errors && errors.organizationNumber && (
                            <div className="alert alert-danger p-2 mt-1 text-xs">{t("organizationNumberRequired")}</div>
                        )}
                    </div>
                    <div className="form-group">
                        <label>{t("email")}:</label>
                        <Input
                            type="email"
                            className="form-control"
                            placeholder={t("email")}
                            name="email"
                            defaultValue={editableItem?.email}
                            ref={register({
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: t("invalidEmailAdress"),
                                },
                            })}
                        />
                        {errors.email && errors.email.message && (
                            <div className="alert alert-warning p-2 mt-1 text-xs">{errors.email.message}</div>
                        )}
                    </div>
                    <div className="form-group">
                        <label>{t("phoneNumber")}:</label>
                        <Input
                            type="text"
                            className="form-control"
                            placeholder={t("phoneNumber")}
                            name="phoneNumber"
                            defaultValue={editableItem?.phoneNumber}
                            ref={register()}
                        />
                    </div>
                    <div className="d-flex flex-row">
                        <div className="form-group">
                            <label>
                                {GENERIC_ITEMS_METADATA[itemLabel].singularUpper} {t("image")}:
                            </label>
                            <UploadImage
                                className="btn btn-sm btn-outline b-info text-info"
                                buttonText={t("uploadImage")}
                                uploadWrapComponent={Button}
                                onUploadImage={(image) => {
                                    dispatch(uploadImageAction(image));
                                }}
                            />
                        </div>
                        {imagePath && (
                            <div className="form-group">
                                <img className="uploaded-image" src={imagePath} alt={itemLabel} />
                            </div>
                        )}
                    </div>
                    {GENERIC_ITEMS_METADATA[itemLabel].contextualItem && (
                        <div className="d-flex flex-column address-wrap">
                            <p>{t("address")}</p>
                            <div className="d-flex flex-row">
                                <div className="form-group pr-2">
                                    <label>{t("country")}:</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="address.country"
                                        disabled
                                        defaultValue={"Sweden"}
                                        ref={register()}
                                    />
                                </div>
                                <div className="form-group pl-2">
                                    <label>{t("city")}:</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="address.city"
                                        defaultValue={editableItem?.address?.city}
                                        placeholder={t("selectCity")}
                                        ref={register()}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-row">
                                <div className="form-group pr-2 mt-0">
                                    <label>{t("postalCode")}: </label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="address.postalCode"
                                        placeholder={t("postalCode")}
                                        max="5"
                                        defaultValue={editableItem?.address?.postalCode}
                                        ref={register({
                                            maxLength: 5,
                                        })}
                                    />
                                    {errors && errors.address?.postalCode && (
                                        <div className="alert alert-danger p-2 mt-1 text-xs">
                                            {t("postalCodeErrorMessage")}.
                                        </div>
                                    )}
                                </div>
                                <div className="form-group pl-2  mt-0">
                                    <label>{t("street")}:</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="address.street"
                                        placeholder={t("street")}
                                        defaultValue={editableItem?.address?.street}
                                        ref={register()}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {loading && <Loader size="md" center backdrop content={t("saving")} />}
                </Modal.Body>
                <Modal.Footer className="mt-3">
                    <Button className="btn btn-sm primary" type="submit" disabled={loading}>
                        {!loading ? `${t("save")} ${GENERIC_ITEMS_METADATA[itemLabel].singularUpper}` : t("saving")}
                    </Button>
                    <Button
                        className="btn btn-sm ml-2 btn-outline b-primary text-primary"
                        onClick={() => toggleOpenModal(!isOpen)}
                        appearance="subtle"
                    >
                        {t("cancel")}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export { ManageItemModal };
