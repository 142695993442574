import axios from "axios";
import { defaultTo } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSetUrlWithContextParams } from "../../lib/hooks/useSetUrlWithContextParams";
import { useDispatch } from "react-redux";
import { setNotificationAction, setLoadingStateAction } from "../../actions/ApplicationActions";
import { NOTIFICATION_TYPE } from "../../constants/general";

interface IProps {
    downloadUrl: string;
    fileName?: string;
    buttonName?: string;
}

const DownloadButton: React.FC<IProps & React.HTMLAttributes<HTMLButtonElement>> = ({
    downloadUrl,
    fileName = "downloadPDF.pdf",
    buttonName = "downloadPDF",
    ...props
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const url = useSetUrlWithContextParams(downloadUrl);

    return (
        <button
            className={`btn btn-sm btn-outline b-primary text-primary ${props.className}`}
            onClick={() => {
                dispatch(setLoadingStateAction(true));
                axios({
                    url,
                    method: "GET",
                    responseType: "blob",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }).then(
                    (response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();

                        dispatch(setLoadingStateAction(false));
                    },
                    (e) => {
                        dispatch(
                            setNotificationAction({
                                type: NOTIFICATION_TYPE.ERROR,
                                content: JSON.stringify(e),
                            })
                        );
                        dispatch(setLoadingStateAction(false));
                    }
                );
            }}
        >
            {t(buttonName)}
        </button>
    );
};

export { DownloadButton };
