import * as React from "react";
import { useSelector } from "react-redux";
import { Notification } from "rsuite";
import { getNotification } from "../selectors/ApplicationSelector";
import { useTranslation } from "react-i18next";
import { defaultTo } from "lodash";

const Notifications: React.FC = () => {
    const notification = useSelector(getNotification);
    const { t } = useTranslation();
    React.useEffect(() => {
        if (notification) {
            (Notification as any)[notification?.type as string]({
                title: t(notification.type),
                duration: defaultTo(notification.duration, 5000),
                description: notification.formatted ? (
                    <pre className="formatted-notification">{notification?.content}</pre>
                ) : (
                    <div>{notification.content}</div>
                ),
            });
        }
    }, [notification]);
    return <></>;
};

export { Notifications };
