import { defaultTo, get } from "lodash";
import * as React from "react";
import { ROLE_DISPLAY } from "../constants/general";
import { getAvatarContent, getUser, getUserRole } from "../helpers/UserHelper";
import { memo } from "react";
import { useSelector } from "react-redux";
import { getCurrentlyLoggedInUser } from "../selectors/UserSelector";

const UserProfile: React.FC = () => {
    const user = useSelector(getCurrentlyLoggedInUser);
    const displayName = defaultTo(user?.name, user?.email);

    return (
        <div className="user-profile-sidemenu d-flex flex-column text-center">
            {user?.picture && <img className="profile-avatar" src={user?.picture} alt="user profile" />}
            {!user?.picture && (
                <span
                    className="_600 text-md accent pull-left profile-avatar"
                    style={{ background: user?.avatarColor }}
                >
                    {getAvatarContent(displayName)}
                </span>
            )}
            <div className="d-flex flex-column user-details">
                <span className="_600">{displayName}</span>
                <span className="text-grey _300">{get(ROLE_DISPLAY, getUserRole())}</span>
            </div>
        </div>
    );
};

export default memo(UserProfile);
