/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "@emotion/styled";
import React from "react";
import { isFileImage } from "../helpers/GeneralHelper";
import { useDispatch } from "react-redux";
import { setNotificationAction } from "../actions/ApplicationActions";
import { NOTIFICATION_TYPE } from "../constants/general";
import { useTranslation } from "react-i18next";

interface IProps {
    onUploadImage: (file: FormData, fileName?: string) => void;
    uploadWrapComponent?: React.ComponentType<any>;
    buttonText?: string;
    buttonType?: any;
}

const UploadImageWrap = styled("div")({
    overflow: "hidden",
    position: "relative",

    "> input": {
        cursor: "pointer",
        display: "block",
        fontSize: "999px",
        filter: "alpha(opacity=0)",
        minHeight: "100%",
        minWidth: "100%",
        opacity: 0,
        position: "absolute",
        right: "0",
        zIndex: 1,
        textAlign: "right",
        top: "0",
    },
});

const UploadAnchor = styled("a")({});

const UploadImage: React.FC<IProps & React.HTMLAttributes<HTMLDivElement>> = ({
    onUploadImage,
    buttonText = "Upload File",
    buttonType,
    uploadWrapComponent: UploadComponent = UploadAnchor,
    ...props
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleChange = (e: any) => {
        if (e.target.files.length) {
            const file = e.target.files[0];

            if (isFileImage(file)) {
                const formData = new FormData();
                formData.append("formFile", file);
                onUploadImage(formData);
            } else {
                dispatch(
                    setNotificationAction({
                        type: NOTIFICATION_TYPE.ERROR,
                        content: t("uploadImageError"),
                    })
                );
            }
        }
    };

    return (
        <UploadImageWrap>
            <input type="file" onChange={handleChange} />
            <UploadComponent {...buttonType} {...props}>
                {buttonText}
            </UploadComponent>
        </UploadImageWrap>
    );
};

export { UploadImage };
