import { StoreState } from '../store';
import { IProductCategories } from '../types/Report';

const getZReport = (state: StoreState) => state.report.zReport;
const getTimeTrackingReport = (state: StoreState) => state.report.timeTrackingReport;
const getReceipts = (state: StoreState) => state.report.receipts;
const getCashiers = (state: StoreState) => state.report.cashiers;
const getProductCategories = (state: StoreState) => state.report.productCategories as IProductCategories;

export { getZReport, getTimeTrackingReport, getReceipts, getCashiers, getProductCategories }
