import DownloadOrderPDF from "../components/orders/DownloadOrderPDF";
import EmailOrderPDF from "../components/orders/EmailOrderPDF";
import i18n from "../i18n";
import { IItemDetails } from "../types/General";
import ViewDetails from "../components/sync/ViewDetails";
import { PreviewOrderPDF } from "../components/orders/PreviewOrderPDF";
import { addMonths, endOfDay, endOfMonth, startOfMonth, startOfYear } from "date-fns";

const passwordLength = 8;
const userNameMinLength = 2;

const REPORT_PDF_DESTINATION_EMAIL = "reportPDFDestinationEmail";

enum NOTIFICATION_TYPE {
    SUCCESS = "success",
    WARNING = "warning",
    ERROR = "error",
}

enum HTTP_METHOD {
    GET = "get",
    POST = "post",
    PUT = "put",
    DELETE = "delete",
}

enum GENERIC_CRUD_ITEM {
    RESELLERS = "resellers",
    STORES = "stores",
    CLIENTS = "clients",
    LICENSES = "licenses",
    CASHIERS = "cashiers",
    ORDERS = "orders",
    SYNC = "sync",
    GIFT_CARDS = "gift-Cards",
}

enum ROLE {
    SUPER_ADMIN = "super-admin",
    CLIENT_ADMIN = "client-admin",
    RESELLER = "reseller",
    ACCOUNTANT = "accountant",
}

const ROLE_DISPLAY = {
    [ROLE.SUPER_ADMIN]: i18n.t("superAdmin"),
    [ROLE.CLIENT_ADMIN]: i18n.t("clientAdmin"),
    [ROLE.RESELLER]: i18n.t("resellerSingularUpper"),
    [ROLE.ACCOUNTANT]: i18n.t("accountant"),
};

enum CONTEXT {
    STORE,
    CLIENT,
}

const GENERIC_ITEMS_METADATA: IItemDetails = {
    [GENERIC_CRUD_ITEM.RESELLERS]: {
        id: "resellerId",
        singular: i18n.t("resellerSingular"),
        singularUpper: i18n.t("resellerSingularUpper"),
        upper: i18n.t("resellerUpper"),
        icon: "supervisor_account",
        tableColumnLabels: [i18n.t("name"), i18n.t("organizationNumber"), i18n.t("email"), i18n.t("phoneNumber")],
        tableColumnItemKeys: ["name", "organizationNumber", "email", "phoneNumber"],
    },
    [GENERIC_CRUD_ITEM.STORES]: {
        id: "storeId",
        singular: i18n.t("resellerSingular"),
        singularUpper: i18n.t("storeSingularUpper"),
        upper: i18n.t("storeUpper"),
        icon: "store",
        searchable: true,
        tableColumnLabels: [i18n.t("name"), i18n.t("organizationNumber"), i18n.t("email"), i18n.t("phoneNumber")],
        tableColumnItemKeys: ["name", "organizationNumber", "email", "phoneNumber"],
        contextualItem: true,
    },
    [GENERIC_CRUD_ITEM.CLIENTS]: {
        id: "clientId",
        singular: i18n.t("clientSingular"),
        singularUpper: i18n.t("clientSingularUpper"),
        upper: i18n.t("clientUpper"),
        icon: "supervisor_account",
        searchable: true,
        tableColumnLabels: [i18n.t("name"), i18n.t("organizationNumber"), i18n.t("email"), i18n.t("phoneNumber")],
        tableColumnItemKeys: ["name", "organizationNumber", "email", "phoneNumber"],
        contextualItem: true,
    },

    [GENERIC_CRUD_ITEM.LICENSES]: {
        id: "licenseId",
        singular: i18n.t("licenseSingular"),
        singularUpper: i18n.t("licenseSingularUpper"),
        upper: i18n.t("licenseUpper"),
        icon: "vpn_key",
        tableColumnLabels: [
            i18n.t("key"),
            i18n.t("deviceIdentifier"),
            i18n.t("inUse"),
            i18n.t("active"),
            i18n.t("validFromDate"),
            i18n.t("validFromDate"),
        ],
        tableColumnItemKeys: ["key", "deviceIdentifier", "inUse", "active", "validFromDate", "validToDate"],
    },
    [GENERIC_CRUD_ITEM.CASHIERS]: {
        id: "cashierId",
        singular: i18n.t("cashierSingular"),
        singularUpper: i18n.t("cashierSingularUpper"),
        upper: i18n.t("cashierUpper"),
        icon: "face",
        readOnly: true,
        tableColumnLabels: [
            i18n.t("name"),
            i18n.t("personalNumber"),
            i18n.t("phoneNumber"),
            i18n.t("address"),
            i18n.t("pin"),
        ],
        tableColumnItemKeys: ["name", "personalNumber", "phoneNumber", "address", "pin"],
    },
    [GENERIC_CRUD_ITEM.ORDERS]: {
        id: "orderId",
        singular: i18n.t("orderSingular"),
        singularUpper: i18n.t("orderSingularUpper"),
        upper: i18n.t("orderUpper"),
        icon: "content_paste",
        readOnly: true,
        tableColumnLabels: [
            i18n.t("verificationNumber"),
            i18n.t("customer"),
            i18n.t("date"),
            i18n.t("brutto"),
            i18n.t("total"),
        ],
        tableColumnItemKeys: ["verificationNumber", "customerName", "date", "brutto", "total"],
        additionalActions: [DownloadOrderPDF, PreviewOrderPDF, EmailOrderPDF],
    },
    [GENERIC_CRUD_ITEM.GIFT_CARDS]: {
        id: "giftCardId",
        singular: i18n.t("giftCardSingular"),
        singularUpper: i18n.t("giftCardSingularUpper"),
        upper: i18n.t("giftCardUpper"),
        icon: "card_giftcard",
        readOnly: true,
        tableColumnLabels: [
            i18n.t("cardNumber"),
            i18n.t("name"),
            i18n.t("amount"),
            i18n.t("startingAmount"),
            i18n.t("discount"),
            i18n.t("createdDate"),
            i18n.t("expirationDate"),
            i18n.t("cashierSingularUpper"),
        ],
        tableColumnItemKeys: [
            "cardNumber",
            "name",
            "amount",
            "startingAmount",
            "discount",
            "createdDate",
            "expirationDate",
            "cashier",
        ],
    },
    [GENERIC_CRUD_ITEM.SYNC]: {
        id: "syncId",
        singular: i18n.t("sync"),
        singularUpper: i18n.t("sync"),
        upper: i18n.t("sync"),
        icon: "sync",
        tableColumnLabels: [
            i18n.t("status"),
            i18n.t("message"),
            i18n.t("storeName"),
            i18n.t("slaveIORunning"),
            i18n.t("slaveSQLRunning"),
        ],
        readOnly: true,
        tableColumnItemKeys: ["status", "message", "store.name", "slaveIORunning", "slaveSQLRunning"],
        additionalActions: [ViewDetails],
    },
};

const moneyRelatedAmountLabels = ["brutto", "total", "discount", "amount", "startingAmount", "netto"];

const RangePickerCustomRanges = [
    {
        label: i18n.t("thisMonth"),
        value: [startOfMonth(new Date()), endOfDay(new Date())],
    },
    {
        label: i18n.t("previousMonth"),
        value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    },
    {
        label: i18n.t("wholeYear"),
        value: [startOfYear(new Date()), endOfDay(new Date())],
    },
];

export {
    passwordLength,
    REPORT_PDF_DESTINATION_EMAIL,
    NOTIFICATION_TYPE,
    HTTP_METHOD,
    GENERIC_CRUD_ITEM,
    GENERIC_ITEMS_METADATA,
    ROLE,
    CONTEXT,
    ROLE_DISPLAY,
    moneyRelatedAmountLabels,
    userNameMinLength,
    RangePickerCustomRanges,
};
