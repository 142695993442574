import { IZReportFilter, ITimeTrackingFilter } from "../types/Report";
import { defaultTo } from "lodash";
import { PRODUCT_CATEGORY } from "../constants/reports";
import { API_ROUTES } from "../constants/routes";

const getFetchZReportQueryParamsFromCriteria = (
    filterCriteria: IZReportFilter | undefined,
    pageNumber: number | undefined
) => {
    let queryParams = "";
    const page = defaultTo(pageNumber, 1);

    if (filterCriteria?.fromDate) {
        queryParams = `?fromDate=${filterCriteria.fromDate}&toDate=${filterCriteria.toDate}&page=${page}`;
        if (filterCriteria.zReportNumber) {
            queryParams = `${queryParams}&zReportNumber=${filterCriteria.zReportNumber}`;
        }
    } else if (filterCriteria?.zReportNumber) {
        queryParams = `?zReportNumber=${filterCriteria.zReportNumber}&page=${page}`;
    } else {
        queryParams = `?page=${page}`;
    }
    return queryParams;
};

const getFetchTimeTrackingReportQueryParamsFromCriteria = (
    filterCriteria: ITimeTrackingFilter | undefined,
    pageNumber: number | undefined
) => {
    let queryParams = "";
    const page = defaultTo(pageNumber, 1);

    if (filterCriteria?.fromDate) {
        queryParams = `?fromDate=${filterCriteria.fromDate}&toDate=${filterCriteria.toDate}&page=${page}`;
        if (filterCriteria.cashierId) {
            queryParams = `${queryParams}&cashierId=${filterCriteria.cashierId}`;
        }
    } else if (filterCriteria?.cashierId) {
        queryParams = `?cashierId=${filterCriteria.cashierId}&page=${page}`;
    } else {
        queryParams = `?page=${page}`;
    }
    return queryParams;
};

const getProductDownloadLinkPath = (category: string) => {
    switch (category) {
        case PRODUCT_CATEGORY.MAIN_PRODUCT_GROUPS:
            return `${process.env.REACT_APP_API_ROOT_URL}${API_ROUTES.TRANSACTIONS}/reports/products/main-product-groups/download`;
        case PRODUCT_CATEGORY.PRODUCT_GROUPS:
            return `${process.env.REACT_APP_API_ROOT_URL}${API_ROUTES.TRANSACTIONS}/reports/products/product-groups/download`;
        case PRODUCT_CATEGORY.PRODUCTS:
            return `${process.env.REACT_APP_API_ROOT_URL}${API_ROUTES.TRANSACTIONS}/reports/products/download`;
    }
};

const getProductEmailLinkPath = (category: string) => {
    switch (category) {
        case PRODUCT_CATEGORY.MAIN_PRODUCT_GROUPS:
            return `${process.env.REACT_APP_API_ROOT_URL}${API_ROUTES.TRANSACTIONS}/reports/products/main-product-groups/email`;
        case PRODUCT_CATEGORY.PRODUCT_GROUPS:
            return `${process.env.REACT_APP_API_ROOT_URL}${API_ROUTES.TRANSACTIONS}/reports/products/product-groups/email`;
        case PRODUCT_CATEGORY.PRODUCTS:
            return `${process.env.REACT_APP_API_ROOT_URL}${API_ROUTES.TRANSACTIONS}/reports/products/email`;
        default:
            return "";
    }
};

export {
    getFetchZReportQueryParamsFromCriteria,
    getFetchTimeTrackingReportQueryParamsFromCriteria,
    getProductDownloadLinkPath,
    getProductEmailLinkPath,
};
