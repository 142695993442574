import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

const LogoutButton = () => {
    const { logout } = useAuth0();
    const { t } = useTranslation();

    return (
        <button
            onClick={() => {
                logout({
                    returnTo: window.location.origin,
                });

                // do localStorage cleanup
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                localStorage.removeItem("x-client-id");
                localStorage.removeItem("x-store-id");
            }}
            className="btn btn-outline b-black text-black btn-sm"
        >
            {t("logOut")}
        </button>
    );
};

export default LogoutButton;
