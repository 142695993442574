import * as React from "react";

interface IProps {
    title: string;
    totalListItems?: number;
}

const ListHeader: React.FC<IProps & React.HTMLAttributes<HTMLDivElement>> = ({
    title,
    totalListItems,
    children,
    ...props
}) => {
    return (
        <>
            <div className="box-header m-b-xs" {...props}>
                <div className="row">
                    <div className="col-sm-5 col-xs-5 header-title">
                        <h2>
                            {title}{" "}
                            {(totalListItems !== 0) && (
                                <span className="label warning">{totalListItems}</span>
                            )}
                        </h2>
                    </div>
                    <div className="col-sm-7 d-flex flex-row-reverse">{children}</div>
                </div>
            </div>
            <div className="box-divider m-0"></div>
        </>
    );
};

export { ListHeader };
