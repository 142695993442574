import * as React from "react";
import { useTranslation } from "react-i18next";
import { ViewSyncDetailsModal } from "../modals/ViewSyncDetailsModal";

const ViewDetails: React.FC<any> = ({ item }) => {
    const { t } = useTranslation();
    const [showViewDetails, setViewDetails] = React.useState<boolean>(false);
    return (
        <>
            <button
                className="btn btn-sm btn-outline b-info text-info"
                onClick={() => setViewDetails(true)}
            >
                {t("viewDetails")}
            </button>
            <ViewSyncDetailsModal
                isOpen={showViewDetails}
                toggleOpenModal={setViewDetails}
                syncObject={item}
            />
        </>
    );
};

export default ViewDetails;