import { ContextActions, IAllClientActions } from "../actions/ContextActions";

interface IClientData {
    contextList: null | any;
}

const defaultState = {
    contextList: null,
};

export default (state: IClientData = defaultState, action: IAllClientActions) => {
    switch (action.type) {
        case ContextActions.SET_CONTEXT_LIST:
            return { ...state, contextList: action.payload.contextList }

        default:
            return state;
    }
};
