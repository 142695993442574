import * as React from "react";
import { API_ROUTES } from "../../constants/routes";
import { DownloadButton } from "../common/DownloadButton";

const DownloadOrderPDF: React.FC<any> = ({ item }) => {
    return (
        <DownloadButton
            fileName={`order-report`}
            downloadUrl={`${process.env.REACT_APP_API_ROOT_URL}${API_ROUTES.ORDERS_PDF}/${item.orderId}/download`}
        />
    );
};

export default DownloadOrderPDF;
