import { map } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DateRangePicker, Pagination } from "rsuite";
import { fetchZReportAction } from "../actions/ReportActions";
import { DownloadButton } from "../components/common/DownloadButton";
import { EmailButton } from "../components/common/EmailButton";
import { ViewPDFButton } from "../components/common/ViewPDFButton";
import DashboardWrapper from "../components/DashboardWrapper";
import { FormattedAmount } from "../components/FormattedAmount";
import { ListHeader } from "../components/ListHeader";
import Input from "../components/_styled/Input";
import PaginationWrap from "../components/_styled/PaginationWrap";
import { API_ROUTES } from "../constants/routes";
import { customFormatDate } from "../helpers/GeneralHelper";
import { getZReport } from "../selectors/ReportSelector";

const ZReport: React.FC = () => {
    const dispatch = useDispatch();
    const zReport = useSelector(getZReport);
    const { t } = useTranslation();

    const [fromDate, setFromDate] = React.useState<string>();
    const [toDate, setToDate] = React.useState<string>();
    const [page, setPage] = React.useState<number>(1);
    const [zReportNumber, setZReportNumber] = React.useState<number>();

    const onChangeDateRange = (value: Array<any>) => {
        setFromDate(value[0] ? customFormatDate(value[0]) : undefined);
        setToDate(value[1] ? customFormatDate(value[1]) : undefined);
    };

    React.useEffect(() => {
        dispatch(
            fetchZReportAction(
                {
                    fromDate,
                    toDate,
                    zReportNumber,
                },
                page
            )
        );
    }, [fromDate, toDate, zReportNumber, page]);

    return (
        <DashboardWrapper>
            <div className="box">
                <ListHeader title="Z Report" totalListItems={zReport?.totalItems}>
                    <Input
                        type="text"
                        className="form-control ml-2 rounded-lg"
                        placeholder={t("filterByZNumber")}
                        onChange={(e) => setZReportNumber(parseInt(e.target.value))}
                    />
                    <DateRangePicker
                        placement="bottomEnd"
                        style={{ width: 260 }}
                        onChange={onChangeDateRange}
                        placeholder={t("dateRangeFilter")}
                    />
                </ListHeader>
                <div className="table-responsive">
                    {zReport?.results.length === 0 && (
                        <div className="alert alert-warning" role="alert">
                            {t("noZData")}.
                        </div>
                    )}
                    {zReport?.results && zReport?.results.length > 0 && (
                        <>
                            <table className="table table-striped b-t">
                                <thead>
                                    <tr>
                                        <th>{t("zNumber")}</th>
                                        <th className="pull-right">{t("totalNumber")}</th>
                                        <th>{t("printedAt")}</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {map(zReport?.results, (report, index) => {
                                        const pdfUrl = `${process.env.REACT_APP_API_ROOT_URL}${API_ROUTES.Z_REPORT_PDF}${report.zReportId}/download`;
                                        return (
                                            <tr key={index}>
                                                <td>{report.zReportNumber}</td>
                                                <td>
                                                    <FormattedAmount amount={report.total} />
                                                </td>
                                                <td>{report.printedAt}</td>
                                                <td>
                                                    <DownloadButton fileName="z-report.pdf" downloadUrl={pdfUrl} />
                                                </td>
                                                <td>
                                                    <ViewPDFButton previewUrl={`${pdfUrl}?preview=true`} />
                                                </td>
                                                <td>
                                                    <EmailButton
                                                        url={`${API_ROUTES.Z_REPORT}/${report.zReportId}/email`}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            {zReport.pageCount > 1 && (
                                <PaginationWrap>
                                    <Pagination
                                        prev
                                        next
                                        onSelect={(page) => setPage(page)}
                                        pages={zReport.pageCount}
                                        activePage={zReport.currentPage}
                                    />
                                </PaginationWrap>
                            )}
                        </>
                    )}
                </div>
            </div>
        </DashboardWrapper>
    );
};

export { ZReport };
