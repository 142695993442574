import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ROUTES } from "../constants/routes";
import { Redirect, withRouter } from "react-router";
import { useTranslation } from "react-i18next";

const Login: React.FC = () => {
    const { loginWithRedirect, isAuthenticated } = useAuth0();
    const { t } = useTranslation();

    if (isAuthenticated) {
        return <Redirect to={ROUTES.DASHBOARD} />;
    }

    return (
        <div className="center-block w-xxl w-auto-xs p-y-md">
            <div className="p-a-md box-color r box-shadow-z1 text-color m-a text-center">
                <button onClick={() => loginWithRedirect()} className="btn btn-fw primary pointer">
                    {t('signIn')}
                </button>
            </div>
        </div>
    );
};

export default withRouter(Login);
