import * as React from "react";
import { map } from "lodash";
import { IDashboard } from "../../types/General";

const CashiersTable: React.FC<{cashiers?: IDashboard["loggedInCashiers"]}> = ({ cashiers }) => {
    return (
        <div className="row">
            <div className="col-md-12 pl-1">
                <div className="box p-a-md">
                    <h6 className="m-b-md fw-bold">Logged in cashiers</h6>
                    <table className="table table-striped b-t table-sm">
                        <thead>
                            <tr>
                                <th className="px-0 py-1">Name</th>
                                <th className="text-right px-0 py-1">Logged In At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {map(cashiers, (cashier) => (
                                <tr>
                                    <td className="px-0 py-1">{cashier?.name}</td>
                                    <td className="text-right px-0 py-1">{cashier.loggedInAt}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export {
    CashiersTable,
};
