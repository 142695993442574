import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { Button, DatePicker, Modal, Loader } from "rsuite";
import { GENERIC_CRUD_ITEM, GENERIC_ITEMS_METADATA } from "../../constants/general";
import { IModalProps } from "../../types/General";
import { editItemAction, addNewItemAction } from "../../actions/GenericCRUDActions";
import { get } from "lodash";
import { getLoadingState } from "../../selectors/ApplicationSelector";
import { useTranslation } from "react-i18next";
import { isBefore } from "date-fns";

type IProps = {
    editableItem: any;
} & IModalProps;

const ManageLicenseModal: React.FC<IProps> = ({ editableItem, isOpen, toggleOpenModal }) => {
    const loading = useSelector(getLoadingState);
    const itemLabel = GENERIC_CRUD_ITEM.LICENSES;
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [active, setActive] = React.useState<boolean>(true);
    const [validFromDate, setValidFromDate] = React.useState<Date | undefined>();
    const [validToDate, setValidToDate] = React.useState<Date | undefined>();

    const saveLicense = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const item = {
            active,
            validFromDate,
            validToDate,
        };
        if (editableItem) {
            dispatch(editItemAction(item, get(editableItem, GENERIC_ITEMS_METADATA[itemLabel].id), itemLabel));
        } else {
            dispatch(addNewItemAction(item, itemLabel));
        }
    };

    React.useEffect(() => {
        setActive(get(editableItem, "active", true));
        setValidFromDate(editableItem?.validFromDate);
        setValidToDate(editableItem?.validToDate);
    }, [editableItem]);

    return (
        <Modal size="xs" show={isOpen} onHide={() => toggleOpenModal(!isOpen)}>
            <form onSubmit={(e) => saveLicense(e)}>
                <Modal.Header>
                    <Modal.Title>
                        {editableItem
                            ? `${t("edit")} ${GENERIC_ITEMS_METADATA[itemLabel].singularUpper}`
                            : `${t("addNew")} ${GENERIC_ITEMS_METADATA[itemLabel].singularUpper}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group d-flex flex-column">
                        <label>{t("active")}:</label>
                        <Switch
                            checkedIcon={false}
                            uncheckedIcon={false}
                            handleDiameter={20}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={13}
                            width={38}
                            onChange={() => setActive(!active)}
                            checked={active}
                        />
                    </div>
                    <div className="form-group d-flex flex-column">
                        <label>{t("validFromDate")}</label>
                        <DatePicker
                            disabledDate={(date) => isBefore(date as Date, new Date())}
                            placeholder={t("validFromDate")}
                            value={validFromDate}
                            oneTap
                            onSelect={setValidFromDate}
                        />
                    </div>
                    <div className="form-group d-flex flex-column">
                        <label>{t("validToDate")}:</label>
                        <DatePicker
                            disabledDate={(date) => isBefore(date as Date, new Date())}
                            placeholder={t("validToDate")}
                            value={validToDate}
                            oneTap
                            onChange={setValidToDate}
                        />
                    </div>
                    {loading && <Loader size="md" center backdrop content={t("saving")} />}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-sm primary" type="submit" disabled={loading}>
                        {!loading ? `${t("save")} ${GENERIC_ITEMS_METADATA[itemLabel].singularUpper}` : t("saving")}
                    </Button>
                    <Button
                        className="btn btn-sm ml-2 btn-outline b-primary text-primary"
                        onClick={() => toggleOpenModal(!isOpen)}
                        appearance="subtle"
                        disabled={loading}
                    >
                        {t("cancel")}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export { ManageLicenseModal };
