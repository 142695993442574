import i18n from "../i18n";
import { CONTEXT, ROLE } from "./general";
import { MENU_ITEM_TYPE } from "./menuItems";

const futureMenuItems = [
    // Programming
    {
        name: i18n.t("artiklar"),
        icon: "assessment",
        subset: MENU_ITEM_TYPE.PROGRAMMING,
        link: `/future-items/artiklar`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: i18n.t("huvudgrupper"),
        icon: "assessment",
        subset: MENU_ITEM_TYPE.PROGRAMMING,
        link: `/future-items/huvudgrupper`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: i18n.t("varugrupper"),
        icon: "assessment",
        subset: MENU_ITEM_TYPE.PROGRAMMING,
        link: `/future-items/varugrupper`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    // {
    //     name: i18n.t("kassörer"),
    //     icon: "assessment",
    //     subset: MENU_ITEM_TYPE.PROGRAMMING,
    //     link: `/future-items/kassörer`,
    //     context: CONTEXT.STORE,
    //     accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    // },
    {
        name: i18n.t("kvittodesign"),
        icon: "assessment",
        subset: MENU_ITEM_TYPE.PROGRAMMING,
        link: `/future-items/kvittodesign`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: i18n.t("betalsätt"),
        icon: "assessment",
        subset: MENU_ITEM_TYPE.PROGRAMMING,
        link: `/future-items/betalsätt`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: i18n.t("meddelanden"),
        icon: "assessment",
        subset: MENU_ITEM_TYPE.PROGRAMMING,
        link: `/future-items/meddelanden`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: i18n.t("bordskartor"),
        icon: "assessment",
        subset: MENU_ITEM_TYPE.PROGRAMMING,
        link: `/future-items/bordskartor`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    // {
    //     name: i18n.t("pucksystem"),
    //     icon: "assessment",
    //     subset: MENU_ITEM_TYPE.PROGRAMMING,
    //     link: `/future-items/pucksystem`,
    //     accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    // },
    {
        name: i18n.t("happyHour"),
        icon: "assessment",
        subset: MENU_ITEM_TYPE.PROGRAMMING,
        link: `/future-items/happyHour`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: i18n.t("mixMatch"),
        icon: "assessment",
        subset: MENU_ITEM_TYPE.PROGRAMMING,
        link: `/future-items/mixMatch`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: i18n.t("presentkortsmallar"),
        icon: "assessment",
        subset: MENU_ITEM_TYPE.PROGRAMMING,
        link: `/future-items/presentkortsmallar`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: i18n.t("inbetalningUtbetalning"),
        icon: "assessment",
        subset: MENU_ITEM_TYPE.PROGRAMMING,
        link: `/future-items/inbetalningUtbetalning`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: i18n.t("bokföringskonton"),
        icon: "assessment",
        subset: MENU_ITEM_TYPE.PROGRAMMING,
        link: `/future-items/bokföringskonton`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },

    // Order
    // {
    //     name: i18n.t("ordrar"),
    //     icon: "assignment",
    //     subset: MENU_ITEM_TYPE.ORDER_FAKTURA,
    //     link: `/future-items/ordrar`,
    //     accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    // },
    {
        name: i18n.t("fakturor"),
        icon: "assignment",
        subset: MENU_ITEM_TYPE.ORDER_FAKTURA,
        link: `/future-items/fakturor`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: i18n.t("offerter"),
        icon: "assignment",
        subset: MENU_ITEM_TYPE.ORDER_FAKTURA,
        link: `/future-items/offerter`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },

    // Settings
    // {
    //     name: i18n.t("företagsinställningar"),
    //     icon: "build",
    //     subset: MENU_ITEM_TYPE.SETTINGS,
    //     link: `/future-items/företagsinställningar`,
    //     context: CONTEXT.STORE,
    //     accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    // },
    {
        name: i18n.t("emailsystem"),
        icon: "build",
        subset: MENU_ITEM_TYPE.SETTINGS,
        link: `/future-items/emailsystem`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: i18n.t("parametrar"),
        icon: "build",
        subset: MENU_ITEM_TYPE.SETTINGS,
        link: `/future-items/parametrar`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: i18n.t("backup"),
        icon: "build",
        subset: MENU_ITEM_TYPE.SETTINGS,
        link: `/future-items/backup`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: i18n.t("sieSystem"),
        icon: "build",
        subset: MENU_ITEM_TYPE.SETTINGS,
        link: `/future-items/sieSystem`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: i18n.t("fortNox"),
        icon: "build",
        subset: MENU_ITEM_TYPE.SETTINGS,
        link: `/future-items/fortNox`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },


];

export {
    futureMenuItems,
};

