import styled from "@emotion/styled";

const Input = styled("input")({
    borderRadius: 6,
    padding: 10,
    border: "1px solid #e5e5ea",
    flex: 1,
    "&::-webkit-input-placeholder": {
        color: "#a4a4a4",
        opacity: 1,
    },

}, (props: any) => ({
    padding: props.sm ? 6 : 10,
}));

export default Input;
