import { isString } from "lodash";
import moment from "moment";
import { format } from "currency-formatter";

const isFileImage = (file: any) => {
    return file && file["type"].split("/")[0] === "image";
};

const manageLocalStorageItem = (label: string, value: string): void => {
    if (value) {
        localStorage.setItem(label, value);
    } else {
        localStorage.removeItem(label);
    }
};

const formatAmount = (amount: number | string, code: string = "SEK") => {
    return format(isString(amount) ? parseFloat(amount) : amount, { code, thousand: " ", symbol: "kr", precision: 0 });
};

const customFormatDate = (date: Date) => {
    return moment(date).toISOString();
};

// return queryparam prefix accordingly
const getQueryParamPrefix = (url: string) => {
    return /[?&]*=/.test(url) ? "&" : "?";
};

export { isFileImage, manageLocalStorageItem, formatAmount, customFormatDate, getQueryParamPrefix };
