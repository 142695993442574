import { capitalize, last, get, defaultTo } from "lodash";
import { IUser } from "../types/User";

const getAvatarContent = (displayName: string | undefined) => {
    return capitalize(displayName).charAt(0);
};

const getUser = (): IUser => {
    return JSON.parse(defaultTo(localStorage.getItem("user"), "{}"));
};

const getUserRole = (user: IUser = getUser()) => {
    return last(get(user, "http://cashoncash.se/roles", [])) as string;
};

export { getAvatarContent, getUser, getUserRole };
