import * as React from "react";
import { useTranslation } from "react-i18next";
import { API_ROUTES } from "../../constants/routes";
import { EmailButton } from "../common/EmailButton";

const EmailOrderPDF: React.FC<any> = ({ item }) => {
    const { t } = useTranslation();
    return (
        <>
            <EmailButton
                className="btn btn-sm btn-outline b-info text-info"
                url={`${API_ROUTES.ORDERS_PDF}/${item.orderId}/email`}
            >
                {t("emailPDF")}
            </EmailButton>
        </>
    );
};

export default EmailOrderPDF;
