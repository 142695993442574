import { useSelector } from 'react-redux';
import { getClientId, getStoreId } from '../../selectors/ApplicationSelector';
import { getQueryParamPrefix } from '../../helpers/GeneralHelper';

const useSetUrlWithContextParams = (previewUrl: string) => {

    let url = previewUrl;
    const clientId = useSelector(getClientId);
    const storeId = useSelector(getStoreId);

    if (storeId) url = `${url}${getQueryParamPrefix(url)}contextStoreId=${storeId}`;
    if (clientId) url = `${url}${getQueryParamPrefix(url)}contextClientId=${clientId}`;

    return url;
}

export { useSetUrlWithContextParams }
