import * as React from "react";
import { IProduct } from "../../types/Report";
import { FormattedAmount } from "../FormattedAmount";

type IProps = {
    totals: IProduct;
} & React.HTMLAttributes<HTMLTableRowElement>;

const ProductTotals: React.FC<IProps> = ({ totals, ...props }) => {
    return (
        <tr className="total-product-tr" {...props}>
            <td className="product-td">
                <b>Total: {totals.quantity}</b>
            </td>
            <td className="product-td"></td>
            <td className="product-td">
                <b>
                    <FormattedAmount amount={totals.brutto} />
                </b>
            </td>
            <td className="product-td">
                <b>
                    <FormattedAmount amount={totals.vat} />
                </b>
            </td>
            <td className="product-td">
                <b>
                    <FormattedAmount amount={totals.netto} />
                </b>
            </td>
        </tr>
    );
};

export { ProductTotals };
