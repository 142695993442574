import { map } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Switch, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { fetchUserRolesAction, fetchUsersAction } from "../../actions/UserActions";
import DashboardWrapper from "../../components/DashboardWrapper";
import { ManageUserModal } from "../../components/modals/ManageUserModal";
import PrivateRoute from "../../components/PrivateRoute";
import { ROUTES } from "../../constants/routes";
import { getFormattedUsers } from "../../selectors/UserSelector";
import { IUser } from "../../types/User";

const Users: React.FC = () => {
    const [manageUserModal, setManageUserModal] = React.useState<boolean>(false);
    const [targetUser, setTargetUser] = React.useState<IUser | undefined>();
    const users = useSelector(getFormattedUsers);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    React.useEffect(() => {
        if (users.length === 0) {
            dispatch(fetchUsersAction());
            dispatch(fetchUserRolesAction());
        }
    }, []);

    return (
        <DashboardWrapper>
            <Switch>
                <PrivateRoute
                    component={() => (
                        <>
                            <div className="box">
                                <div className="box-header">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h2>
                                                {t("users")} <span className="label warning">{users?.length}</span>
                                            </h2>
                                        </div>
                                        <div className="col-sm-6">
                                            <button
                                                className="btn info pointer pull-right btn-sm"
                                                onClick={() => setManageUserModal(true)}
                                            >
                                                + {t("addNewUser")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-divider"></div>
                                <div className="table-responsive">
                                    <table className="table table-striped b-t">
                                        <thead>
                                            <tr>
                                                <th>{t("email")}</th>
                                                <th>{t("role")}</th>
                                                <th>{t("name")}</th>
                                                <th>{t("phoneNumber")}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {map(users, (user: IUser, index: number) => (
                                                <tr key={index}>
                                                    <td>
                                                        <Link
                                                            className="list-left"
                                                            to={`${ROUTES.USERS}/${user.userId}`}
                                                        >
                                                            {user.email}
                                                        </Link>
                                                    </td>
                                                    <td>{user.role}</td>
                                                    <td>{user.name}</td>
                                                    <td>{user.phoneNumber}</td>
                                                    <td>
                                                        <button
                                                            onClick={() => {
                                                                setManageUserModal(true);
                                                                setTargetUser(user);
                                                            }}
                                                            className="btn btn-sm btn-outline b-info text-info"
                                                        >
                                                            {t("viewEdit")}
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    )}
                    path={ROUTES.USERS}
                    exact
                />
            </Switch>

            <ManageUserModal
                isOpen={manageUserModal}
                toggleOpenModal={() => {
                    setManageUserModal(false);
                    setTargetUser(undefined);
                }}
                user={targetUser}
            />
        </DashboardWrapper>
    );
};

export default withRouter(Users);
