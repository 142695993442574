import { cloneDeep, first, get, keys, map, reduce, set, find, nth, values, flatten, each } from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import { MultiCascader } from "rsuite";
import { IUser } from "../../types/User";
import { getContextList, getStoreClientDictionary } from "../../selectors/ContextSelector";

interface IProps {
    onselectContext: (selectedValues: Record<string, any>) => void;
    context?: { clients: IUser["clients"]; stores: IUser["stores"] };
}
const AssignStoreClient: React.FC<IProps> = ({ onselectContext, context: contextProp }) => {
    const contextList = useSelector(getContextList);
    const storeClientDictionary = useSelector(getStoreClientDictionary);
    let formattedData: Record<string, any>[] = [];

    if (contextList) {
        formattedData = map(cloneDeep(contextList), (client) => ({
            value: `${client.clientId}`,
            label: client.name,
            checked: true,
            children: map(client.stores, (store) => ({
                value: `${client.clientId}-${store.storeId}`,
                label: store.name,
            })),
        }));
    }

    const selectClientStore = (values: Record<string, number>[]) => {
        const formattedContext = reduce(
            values,
            (acc: Record<string, any>, val: any) => {
                const valArray = val.split("-");
                const clientId = parseInt(nth(valArray, 0) as string);
                const storeId = nth(valArray, 1);

                // p.s. God bless lodash
                const storesList = get(acc, "stores", []);
                // if only client is selected, send also the list of corresponding stores
                if (!storeId) {
                    set(
                        acc,
                        "stores",
                        flatten([
                            ...storesList,
                            map(get(find(contextList, { clientId }), "stores"), (store) => ({
                                storeId: store.storeId,
                            })),
                        ])
                    );
                }

                if (!storeId) set(acc, "clients", [...get(acc, "clients", []), { clientId }]);
                if (storeId) set(acc, "stores", [...storesList, { storeId: parseInt(storeId as string) }]);
                return acc;
            },
            {}
        );

        onselectContext(formattedContext);
    };

    const defaultValue = reduce(
        flatten(values(contextProp)),
        (acc: string[], val: Record<string, any>) => {
            const contextKey = first(keys(val)) as string;
            const contextValue = get(val, contextKey);
            acc.push(
                contextKey === "storeId"
                    ? `${get(storeClientDictionary, contextValue)}-${contextValue}`
                    : `${contextValue}`
            );
            return acc;
        },
        []
    );

    return (
        <MultiCascader
            defaultValue={defaultValue}
            placeholder="Select clients/stores"
            data={formattedData}
            onChange={selectClientStore}
        />
    );
};

export { AssignStoreClient };
