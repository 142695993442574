import { map } from "lodash";
import moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DateRangePicker, Pagination, SelectPicker } from "rsuite";
import { fetchCashiersAction, fetchTimeTrackingReportAction } from "../actions/ReportActions";
import { DownloadButton } from "../components/common/DownloadButton";
import { EmailButton } from "../components/common/EmailButton";
import { ViewPDFButton } from "../components/common/ViewPDFButton";
import DashboardWrapper from "../components/DashboardWrapper";
import { ListHeader } from "../components/ListHeader";
import { Time } from "../components/Time";
import PaginationWrap from "../components/_styled/PaginationWrap";
import { API_ROUTES } from "../constants/routes";
import { getCashiers, getTimeTrackingReport } from "../selectors/ReportSelector";
import { ITimeTrackingReport } from "../types/Report";

const TimeTracking: React.FC = () => {
    const dispatch = useDispatch();
    const timeTrackingReport = useSelector(getTimeTrackingReport);
    const cashiers = useSelector(getCashiers);
    const { t } = useTranslation();
    const [cashierId, setCashierId] = React.useState<number>();
    const [fromDate, setFromDate] = React.useState<string>();
    const [toDate, setToDate] = React.useState<string>();
    const [page, setPage] = React.useState<number>(1);


    React.useEffect(() => {
        dispatch(fetchCashiersAction());
        dispatch(
            fetchTimeTrackingReportAction(
                {
                    fromDate,
                    toDate,
                    cashierId,
                },
                page
            )
        );
    }, [fromDate, toDate, cashierId, page]);

    const onChangeDateRange = (value: Array<any>) => {
        setFromDate(value[0] ? moment(value[0]).toISOString() : undefined);
        setToDate(value[1] ? moment(value[1]).toISOString() : undefined);
    };

    const isDataAvailable = !!(
        timeTrackingReport &&
        timeTrackingReport?.results &&
        timeTrackingReport?.results.length > 0
    );
    const disabledClass = !isDataAvailable ? "disabled" : "";

    let selectableCashiers = map(cashiers?.results, (cashier) => ({
        label: cashier.name,
        value: cashier.cashierId,
    }));

    selectableCashiers = [{ value: undefined, label: t("all") }, ...selectableCashiers];

    // might rethink this, make generic function to handle this
    let queryParams = `?page=${page}`;
    if (fromDate) queryParams = `${queryParams}&fromDate=${fromDate}&toDate=${toDate}`;
    if (cashierId) queryParams = `${queryParams}&cashierId=${cashierId}`;

    return (
        <DashboardWrapper>
            <div className="box">
                <ListHeader title="Time Tracking Report" totalListItems={timeTrackingReport?.totalItems}>
                    <div className="row">
                        <div className="col-sm-12 d-flex w-100 justify-content-end">
                            <SelectPicker
                                cleanable={false}
                                placeholder={t("filterByCasshierName")}
                                className="mr-2 w-50"
                                data={selectableCashiers}
                                onChange={setCashierId}
                            />
                            <DateRangePicker
                                className="w-50"
                                placement="bottomEnd"
                                onChange={onChangeDateRange}
                                placeholder={t("dateRangeFilter")}
                            />
                        </div>

                        <div className="col-sm-12 m-y-sm d-flex w-100 justify-content-end timetracking-action-buttons">
                            <EmailButton
                                className={`${disabledClass}`}
                                url={`${API_ROUTES.TIME_TRACKING}/email`}
                                queryParams={queryParams}
                            />
                            <ViewPDFButton
                                className={`${disabledClass} m-r-sm`}
                                previewUrl={`${process.env.REACT_APP_API_ROOT_URL}${API_ROUTES.TIME_TRACKING}/download${queryParams}&preview=true`}
                            />
                            <DownloadButton
                                className={`${disabledClass}`}
                                fileName="timetracking-report.pdf"
                                downloadUrl={`${process.env.REACT_APP_API_ROOT_URL}${API_ROUTES.TIME_TRACKING}/download${queryParams}`}
                            />
                        </div>
                    </div>
                </ListHeader>
                <div className="table-responsive">
                    {!isDataAvailable && (
                        <div className="alert alert-warning" role="alert">
                            {t("noDataToDisplay")}.
                        </div>
                    )}
                    {isDataAvailable && (
                        <>
                            <table className="table table-striped b-t">
                                <thead>
                                    <tr>
                                        <th>{t("name")}</th>
                                        <th>{t("personalNumber")}</th>
                                        <th>{t("timeIn")}</th>
                                        <th>{t("timeOut")}</th>
                                        <th>{t("timeActive")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {map(timeTrackingReport?.results, (item: ITimeTrackingReport, index) => (
                                        <tr key={index}>
                                            <td>{item.name}</td>
                                            <td>{item.personalNumber}</td>
                                            <td>
                                                <Time item={item} label="in" />
                                            </td>
                                            <td>
                                                <Time item={item} label="out" />
                                            </td>
                                            <td>
                                                <Time item={item} label="active" />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {timeTrackingReport?.pageCount && timeTrackingReport.pageCount > 1 && (
                                <PaginationWrap>
                                    <Pagination
                                        prev
                                        next
                                        onSelect={setPage}
                                        pages={timeTrackingReport?.pageCount}
                                        activePage={timeTrackingReport?.currentPage}
                                    />
                                </PaginationWrap>
                            )}
                        </>
                    )}
                </div>
            </div>
        </DashboardWrapper>
    );
};

export { TimeTracking };
