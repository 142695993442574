import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Loader } from "rsuite";

const PrivateRoute = ({ component, ...args }: any) => (
    <Route
        component={withAuthenticationRequired(component, {
            onRedirecting: () => <Loader backdrop size="lg" center />,
        })}
        {...args}
    />
);

export default PrivateRoute;
