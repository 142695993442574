import { map } from "lodash";
import moment from "moment";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Pagination } from "rsuite";
import { fetchReceiptsAction } from "../actions/ReportActions";
import DashboardWrapper from "../components/DashboardWrapper";
import { FormattedAmount } from "../components/FormattedAmount";
import { ListHeader } from "../components/ListHeader";
import PaginationWrap from "../components/_styled/PaginationWrap";
import { API_ROUTES } from "../constants/routes";
import { getReceipts } from "../selectors/ReportSelector";
import Input from "../components/_styled/Input";
import { useDebounce } from "../lib/hooks/Debounce";
import { DownloadButton } from "../components/common/DownloadButton";
import { ViewPDFButton } from "../components/common/ViewPDFButton";
import { EmailButton } from "../components/common/EmailButton";

const Receipts: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const receipts = useSelector(getReceipts);
    const [dateFilter, setDateFilter] = useState<string | undefined>();
    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    React.useEffect(() => {
        dispatch(fetchReceiptsAction(page, { dateFilter, searchTerm }));
    }, [dateFilter, page, debouncedSearchTerm]);

    const formatDate = (date: Date) => moment(date).format("YYYY-MM-DD");

    return (
        <DashboardWrapper>
            <div className="box">
                <ListHeader title={t("receipts")} totalListItems={receipts?.totalItems}>
                    <DatePicker
                        placement="bottomEnd"
                        placeholder="Date Time Filter"
                        style={{ flex: 1 }}
                        onChange={(date) => setDateFilter(date ? formatDate(date) : undefined)}
                    />
                    <Input
                        type="text"
                        className="form-control mr-2 rounded-lg"
                        placeholder={t("searchReceipts")}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </ListHeader>

                <div className="table-responsive">
                    {receipts?.results.length === 0 && (
                        <div className="alert alert-warning" role="alert">
                            {t("noReceiptsData")}.
                        </div>
                    )}
                    {receipts?.results && receipts?.results.length > 0 && (
                        <>
                            <div className="table-responsive">
                                <table className="table table-striped b-t">
                                    <thead>
                                        <tr>
                                            <th>{t("verificationNumber")}</th>
                                            <th>{t("receiptType")}</th>
                                            <th className="pull-right">{t("totalAmount")}</th>
                                            <th>{t("registerId")}</th>
                                            <th>{t("dateTime")}</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {map(receipts?.results, (receipt, index) => {
                                            const pdfUrl = `${process.env.REACT_APP_API_ROOT_URL}${API_ROUTES.RECEIPTS}/${receipt.receiptId}`;
                                            return (
                                                <tr key={index}>
                                                    <td>{receipt.verificationNumber}</td>
                                                    <td>{receipt.receiptType}</td>
                                                    <td>
                                                        <FormattedAmount amount={receipt.totalAmount} />
                                                    </td>
                                                    <td>{receipt.registerId}</td>
                                                    <td>{receipt.dateTime}</td>
                                                    <td>
                                                        {receipt.hasPrintableDocument && (
                                                            <DownloadButton
                                                                fileName={`${receipt.verificationNumber}.pdf`}
                                                                downloadUrl={`${pdfUrl}/download`}
                                                            />
                                                        )}
                                                    </td>
                                                    <td>
                                                        {receipt.hasPrintableDocument && (
                                                            <ViewPDFButton
                                                                previewUrl={`${pdfUrl}/download?preview=true`}
                                                            />
                                                        )}
                                                    </td>
                                                    <td>
                                                        {receipt.hasPrintableDocument && (
                                                            <EmailButton url={`${pdfUrl}/email`} />
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {receipts.pageCount > 1 && (
                                <PaginationWrap>
                                    <Pagination
                                        prev
                                        last
                                        next
                                        first
                                        onSelect={setPage}
                                        pages={receipts.pageCount}
                                        activePage={receipts.currentPage}
                                    />
                                </PaginationWrap>
                            )}
                        </>
                    )}
                </div>
            </div>
        </DashboardWrapper>
    );
};

export { Receipts };
