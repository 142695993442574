import { get } from "lodash";
import moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DateRangePicker } from "rsuite";
import { fetchProductsAction } from "../actions/ReportActions";
import { DownloadButton } from "../components/common/DownloadButton";
import { EmailButton } from "../components/common/EmailButton";
import DashboardWrapper from "../components/DashboardWrapper";
import { ListHeader } from "../components/ListHeader";
import { ProductsGroupsTable } from "../components/orders/ProductGroupsTable";
import { ProductsTable } from "../components/products/ProductsTable";
import { PRODUCT_CATEGORY } from "../constants/reports";
import { getProductDownloadLinkPath, getProductEmailLinkPath } from "../helpers/ReportHelper";
import { getProductCategories } from "../selectors/ReportSelector";

const Products: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = React.useState<string>();
    const [toDate, setToDate] = React.useState<string>();
    const [activeCategory, setActiveCategory] = React.useState<string>(PRODUCT_CATEGORY.MAIN_PRODUCT_GROUPS);

    const productCategories = useSelector(getProductCategories);

    const onChangeDateRange = (value: Array<any>) => {
        setFromDate(value[0] ? moment(value[0]).toISOString() : undefined);
        setToDate(value[1] ? moment(value[1]).toISOString() : undefined);
    };

    React.useEffect(() => {
        dispatch(fetchProductsAction(fromDate, toDate));
    }, [fromDate, toDate]);

    const queryParams = fromDate ? `?toDate=${toDate}&fromDate=${fromDate}` : "";

    return (
        <DashboardWrapper>
            <div className="box products-outer">
                <ListHeader title="Products" totalListItems={20}>
                    <DateRangePicker
                        style={{ width: 260 }}
                        onChange={onChangeDateRange}
                        placeholder={t("dateRangeFilter")}
                        placement="bottomEnd"
                    />
                </ListHeader>
                <div className="d-flex row">
                    <div className="nav nav-md p-y-md m-l-3 products-nav col-sm-8 ">
                        <button
                            className={`nav-link ${
                                activeCategory === PRODUCT_CATEGORY.MAIN_PRODUCT_GROUPS ? "active" : ""
                            }`}
                            onClick={() => setActiveCategory(PRODUCT_CATEGORY.MAIN_PRODUCT_GROUPS)}
                        >
                            {t("categories")}
                        </button>
                        <button
                            className={`nav-link ${activeCategory === PRODUCT_CATEGORY.PRODUCT_GROUPS ? "active" : ""}`}
                            onClick={() => setActiveCategory(PRODUCT_CATEGORY.PRODUCT_GROUPS)}
                        >
                            {t("subcategories")}
                        </button>
                        <button
                            className={`nav-link ${activeCategory === PRODUCT_CATEGORY.PRODUCTS ? "active" : ""}`}
                            onClick={() => setActiveCategory(PRODUCT_CATEGORY.PRODUCTS)}
                        >
                            {t("products")}
                        </button>
                    </div>
                    <div className="d-flex row col-sm-4 p-y-md pull-right justify-content-end product-actions align-self-center">
                        <DownloadButton
                            fileName="products-report.pdf"
                            downloadUrl={`${getProductDownloadLinkPath(activeCategory)}${queryParams}`}
                        />
                        <EmailButton
                            url={getProductEmailLinkPath(activeCategory)}
                            queryParams={queryParams}
                            className="m-x-sm"
                        />
                    </div>
                </div>

                <div className="table-responsive p-t-sm product-table-wrap">
                    {activeCategory === PRODUCT_CATEGORY.PRODUCTS && (
                        <div className="product-table box">
                            <ProductsTable
                                totals={get(productCategories, `${PRODUCT_CATEGORY.PRODUCTS}.totals`)}
                                products={get(productCategories, `${PRODUCT_CATEGORY.PRODUCTS}.products`)}
                            />
                        </div>
                    )}
                    {(activeCategory === PRODUCT_CATEGORY.MAIN_PRODUCT_GROUPS ||
                        activeCategory === PRODUCT_CATEGORY.PRODUCT_GROUPS) && (
                        <ProductsGroupsTable productGroups={get(productCategories, activeCategory)} />
                    )}
                </div>
            </div>
        </DashboardWrapper>
    );
};

export { Products };
