import { pickBy, upperFirst } from 'lodash';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { setNotificationAction } from '../actions/ApplicationActions';
import { fetchContextListAction } from '../actions/ContextActions';
import { fetchItemsAction, GENERIC_CRUD_ACTIONS, IAddNewItem, IDeleteItem, IEditItem, IFetchItems, IUploadImage, setItemsAction, setUploadedImageAction } from '../actions/GenericCRUDActions';
import { GENERIC_ITEMS_METADATA, HTTP_METHOD, NOTIFICATION_TYPE } from '../constants/general';
import { API_ROUTES } from '../constants/routes';
import i18n from '../i18n';
import { IHTTPResponse } from '../types/General';
import { ApiService } from './APISaga';

const GenericCrudSaga = function* () {
    yield all([
        takeEvery(GENERIC_CRUD_ACTIONS.FETCH_ITEMS, fetchItems),
        takeEvery(GENERIC_CRUD_ACTIONS.ADD_NEW_ITEM, addNewItem),
        takeEvery(GENERIC_CRUD_ACTIONS.DELETE_ITEM, deleteItem),
        takeEvery(GENERIC_CRUD_ACTIONS.EDIT_ITEM, editItem),
        takeEvery(GENERIC_CRUD_ACTIONS.UPLOAD_IMAGE, uploadImage),
    ])
}

const handleItemUpdateSuccess = function* (payload: any) {
    const { message, itemLabel } = payload;

    yield put(fetchItemsAction(itemLabel));

    if (GENERIC_ITEMS_METADATA[itemLabel].contextualItem) {
        yield put(fetchContextListAction());
    }

    yield put(setNotificationAction({
        type: NOTIFICATION_TYPE.SUCCESS,
        content: message,
    }));
}

const fetchItems = function* (action: IFetchItems) {
    const { itemLabel, searchTerm } = action.payload;
    try {
        const searchQuery = searchTerm ? `?searchTerm=${searchTerm}` : "";
        const targetUrl = upperFirst(itemLabel);

        const fetchItemsResponse: IHTTPResponse = yield call(
            ApiService,
            {
                method: HTTP_METHOD.GET,
                url: `${API_ROUTES.API_ROOT}/${targetUrl}${searchQuery}`,
            }
        );

        yield put(setItemsAction(fetchItemsResponse.data, itemLabel));
    } catch (e) {
        console.warn(`Error while fetching ${itemLabel}:\n`, e);
    }

};

const addNewItem = function* (action: IAddNewItem) {
    const { item, itemLabel } = action.payload;
    try {
        const targetUrl = upperFirst(itemLabel);

        const cleanedItem = pickBy(item);

        const saveNewItemResponse: IHTTPResponse = yield call(
            ApiService, {
            method: HTTP_METHOD.POST,
            url: `${API_ROUTES.API_ROOT}/${targetUrl}`,
            payload: cleanedItem,
        });

        if (saveNewItemResponse) {
            yield call(
                handleItemUpdateSuccess,
                {
                    message:
                        `${i18n.t("new")} ${GENERIC_ITEMS_METADATA[itemLabel].singular} ${i18n.t("created")}.`,
                    itemLabel,
                }
            );
        }
    } catch (e) {
        console.warn(`Error while creating ${itemLabel}`);
    }
};

const editItem = function* (action: IEditItem) {
    const { item, itemId, itemLabel } = action.payload;
    try {
        const targetUrl = upperFirst(itemLabel);
        const cleanedItem = pickBy(item);

        const saveNewItemResponse: IHTTPResponse = yield call(
            ApiService, {
            method: HTTP_METHOD.PUT,
            url: `${API_ROUTES.API_ROOT}/${targetUrl}/${itemId}`,
            payload: cleanedItem,
        });

        if (saveNewItemResponse) {
            yield call(
                handleItemUpdateSuccess,
                {
                    message:
                        `${i18n.t("successful")} ${GENERIC_ITEMS_METADATA[itemLabel].singular} ${i18n.t("editLower")}.`,
                    itemLabel,
                }
            );
        }
    } catch (e) {
        console.warn(`Error on ${itemLabel} edit`);
    }
};

const deleteItem = function* (action: IDeleteItem) {
    const { itemId, itemLabel } = action.payload;
    try {
        const targetUrl = upperFirst(itemLabel);

        const deleteItem: IHTTPResponse = yield call(
            ApiService, {
            method: HTTP_METHOD.DELETE,
            url: `${API_ROUTES.API_ROOT}/${targetUrl}/${itemId}`,
        });

        if (deleteItem) {
            yield call(
                handleItemUpdateSuccess,
                {
                    message:
                        `${GENERIC_ITEMS_METADATA[itemLabel].singularUpper} ${i18n.t("successfullyDeleted")}.`,
                    itemLabel,
                }
            );
        }
    } catch (e) {
        console.warn(`Error on ${itemLabel} delete`);
    }
};


const uploadImage = function* (action: IUploadImage) {
    try {
        const { image } = action.payload;

        const uploadImageResponse: IHTTPResponse = yield call(
            ApiService, {
            method: HTTP_METHOD.POST,
            url: `${API_ROUTES.IMAGE_UPLOAD}`,
            headers: {
                "Content-type": `multipart/form-data`,
            },
            payload: image,
        });

        if (uploadImageResponse) {
            yield put(setUploadedImageAction(uploadImageResponse.data.url));
            yield put(setNotificationAction({
                type: NOTIFICATION_TYPE.SUCCESS,
                content: i18n.t("imageUploadSuccess"),
            }));
        }
    } catch (e) {
        console.warn(`Upload image error\n`, e);
    }
};

export { GenericCrudSaga };
