import styled from "@emotion/styled";
import { find, flatten, map, reduce, first, get } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { SelectPicker } from "rsuite";
import { setClientIdAction, setStoreIdAction } from "../actions/ApplicationActions";
import { ROLE } from "../constants/general";
import { ROUTES } from "../constants/routes";
import { manageLocalStorageItem } from "../helpers/GeneralHelper";
import { getUserRole } from "../helpers/UserHelper";
import { getClientId, getStoreId } from "../selectors/ApplicationSelector";
import { getContextList } from "../selectors/ContextSelector";

const SelectPickerWrap = styled(SelectPicker)({
    width: "100%",
    alignSelf: "center",
    marginLeft: 10,
});

const ContextSearch: React.FC = () => {
    const contextList = useSelector(getContextList);
    const clientId = useSelector(getClientId);
    const storeId = useSelector(getStoreId);
    const history = useHistory();

    const { t } = useTranslation();

    const userRole = getUserRole();
    const dispatch = useDispatch();

    React.useEffect(() => {
        let clientId = localStorage.getItem("x-client-id");
        let storeId = localStorage.getItem("x-store-id");

        // if storeId nor clientId selected - then select first one of each
        if (!storeId && !clientId) {
            clientId = get(first(contextList), "clientId");
            storeId = get(first(get(first(contextList), "stores")), "storeId");
        }

        if (clientId) dispatch(setClientIdAction(clientId));
        if (storeId) dispatch(setStoreIdAction(storeId));
    }, [contextList]);

    const selectableClientsStores = flatten(
        reduce(
            contextList,
            (acc: any[], client: any) => {
                // if user has accountant permission, disable client select
                if (userRole !== ROLE.ACCOUNTANT) {
                    acc.push({
                        label: client.name,
                        value: { clientId: client.clientId },
                        isClient: true,
                        directlyAssigned: client.directlyAssigned,
                    });
                }
                acc.push(
                    map(client.stores, (store) => ({
                        label: store.name,
                        value: {
                            clientId: client.clientId,
                            storeId: store.storeId,
                        },
                        clientId: client.clientId,
                        directlyAssigned: client.directlyAssigned,
                    }))
                );
                return acc;
            },
            []
        )
    );

    const onSelectContext = (value: any) => {
        const storeId = value ? value.storeId : undefined;
        const clientId = value ? value.clientId : undefined;

        manageLocalStorageItem("x-client-id", clientId);
        manageLocalStorageItem("x-store-id", storeId);

        dispatch(setClientIdAction(clientId));
        dispatch(setStoreIdAction(storeId));

        history.push(!storeId ? ROUTES.STORES : ROUTES.DASHBOARD);
    };

    const disabledValues = reduce(
        selectableClientsStores,
        (acc: string[], item: any) => {
            if (!item.directlyAssigned) {
                acc.push(item.value);
            }
            return acc;
        },
        []
    );

    return (
        <SelectPickerWrap
            data={selectableClientsStores}
            placeholder={t("clientStore")}
            disabledItemValues={disabledValues}
            cleanable={false}
            renderMenuItem={(label, item: any) => {
                return <div style={{ paddingLeft: item.isClient ? "0" : "20px" }}>{label}</div>;
            }}
            value={find(selectableClientsStores, (item) => {
                //  TODO (fix this) sometimes comparing string with number i.e. 1 == "1"
                return item.value.clientId == clientId && item.value.storeId == storeId;
            })}
            onChange={onSelectContext}
            renderValue={(value) => {
                return (
                    <div>
                        <span style={{ color: "#575757" }}>
                            <i className="material-icons">{value.isClient ? "account_box" : "store_mall_directory"}</i>{" "}
                            {value.isClient ? `${t("client")}: ` : `${t("store")}: `}
                        </span>{" "}
                        {value.label}
                    </div>
                );
            }}
        />
    );
};

export { ContextSearch };
