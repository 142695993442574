import * as React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Footer: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className="app-footer">
            <div className="p-2 text-xs">
                <div className="pull-right text-muted py-1">
                    {moment.utc().year()} &copy; {t("copyright")}{" "}
                    <strong>Cashoncash</strong>
                </div>
            </div>
        </div>
    );
};

export default Footer;
