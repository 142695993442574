import * as React from "react";
import { formatAmount } from "../helpers/GeneralHelper";

interface IProps {
    amount: string | number;
    currency?: string;
}

const FormattedAmount: React.FC<IProps> = ({ amount, currency }) => {
    return <span className="pull-right">{formatAmount(amount, currency)}</span>;
};

export { FormattedAmount };
