import * as React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "rsuite";
import { IModalProps } from "../../types/General";
import { ViewPDF } from "../common/ViewPDF";
import Button from "../_styled/Button";

interface IProps {
    url: string;
}

const ViewPDFModal: React.FC<IProps & IModalProps> = ({ url, isOpen, toggleOpenModal }) => {
    const { t } = useTranslation();

    return (
        <Modal size="lg" show={isOpen} onHide={() => toggleOpenModal(!isOpen)}>
            <Modal.Body>{isOpen && <ViewPDF url={url} />}</Modal.Body>
            <Modal.Footer>
                <Button
                    className="btn btn-sm ml-2 btn-outline b-accent text-accent"
                    onClick={() => toggleOpenModal(!isOpen)}
                    appearance="subtle"
                >
                    {t("close")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export { ViewPDFModal };
