import { endOfDay, startOfMonth } from "date-fns";
import moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, withRouter } from "react-router";
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { DateRangePicker } from "rsuite";
import { fetchDashboardDataAction } from "../actions/ApplicationActions";
import { CashiersTable } from "../components/dashboard/CashiersTable";
import { TopSellingProductsTable } from "../components/dashboard/TopSellingProductsTable";
import DashboardWrapper from "../components/DashboardWrapper";
import { RangePickerCustomRanges } from "../constants/general";
import { ROUTES } from "../constants/routes";
import { getDashboard, getStoreId } from "../selectors/ApplicationSelector";
import { formatAmount } from "../helpers/GeneralHelper";
import { format } from "currency-formatter";
import { defaultTo, map } from "lodash";
import { IDashboard } from "../types/General";

const Dashboard: React.FC = () => {
    const dispatch = useDispatch();
    const dashboard = useSelector(getDashboard);
    const storeId = useSelector(getStoreId);
    const { t } = useTranslation();
    const [fromDate, setFromDate] = React.useState<Date>(startOfMonth(new Date()));
    const [toDate, setToDate] = React.useState<Date>(endOfDay(new Date()));

    const onChangeDateRange = (value: Array<any>) => {
        setFromDate(moment(value[0]) as any);
        setToDate(moment(value[1]) as any);
    };

    React.useEffect(() => {
        if (storeId) {
            dispatch(fetchDashboardDataAction(fromDate, toDate));
        }
    }, [fromDate, toDate]);

    const renderCustomizedBarChartLabel = (props: any) => {
        const { x, y, width, height, value } = props;
        const labelPadding = 6;
        return (
            <g>
                <text x={(width < 0 ? x + width : x) - labelPadding} y={y + height / 1.5} fill="#333" textAnchor="end">
                    {value}
                </text>
            </g>
        );
    };

    return (
        <DashboardWrapper>
            <div className="box mb-3">
                <div className="box-header d-flex justify-content-between">
                    <h2 className="align-self-center">Dashboard</h2>
                    <DateRangePicker
                        className="col-sm-4 col-xs-12 datepicker"
                        style={{ width: 320 }}
                        placement="bottomEnd"
                        onChange={onChangeDateRange}
                        cleanable={false}
                        value={[fromDate, toDate]}
                        placeholder={t("dateRangeFilter")}
                        ranges={RangePickerCustomRanges as any}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-7">
                    <div className="box p-a-md p-b-sm">
                        <h6 className="m-b-md fw-bold">Sales by day</h6>
                        <ResponsiveContainer height={280} width="100%">
                            <BarChart data={dashboard?.salesPerDay} margin={{ top: 20, left: 10 }}>
                                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                                <XAxis dataKey="date" hide={true}/>
                                <YAxis tickFormatter={(value) => formatAmount(value as number)}/>
                                <Tooltip formatter={(value) => formatAmount(value as number)}/>
                                <Legend />
                                <Bar dataKey="totalPrice" fill="#509DE2" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="box p-a-md mb-3">
                        <h6 className="m-b-md fw-bold">Payment by type</h6>
                        <ResponsiveContainer height={280} width="100%">
                            <BarChart
                                data={dashboard?.paymentsByTypes}
                                layout="vertical"
                                barCategoryGap={10}
                                margin={{ top: 20, left: 10 }}
                            >
                                <CartesianGrid horizontal={false} strokeDasharray="3 3" />

                                <XAxis type="number" tickFormatter={(value) => formatAmount(value as number)}/>
                                <YAxis type="category" dataKey="totalAmount" hide={true} label="Total Amount"/>
                                <Tooltip formatter={(value) => formatAmount(value as string)}/>
                                <Bar dataKey="totalAmount" fill="#88BE4D" barSize={24} minPointSize={10}>
                                    <LabelList dataKey="type" content={renderCustomizedBarChartLabel} />
                                </Bar>
                                <Legend />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="col-md-5">
                    {/* Needs to be defined */}
                    <div className="row mb-3">
                        <div className="col-md-6 pl-1">
                            <div className="box d-flex flex-column p-x-md p-y-lg h-100 align-items-center justify-content-center">
                                <h3 className="font-weight-bolder">{dashboard?.totalNumberOfCustomers}</h3>
                                <span className="mb-0">Total number of customers today</span>
                            </div>
                        </div>
                        <div className="col-md-6 pl-1">
                            <div className="box d-flex flex-column p-x-md p-y-lg h-100 align-items-center justify-content-center">
                                <h3 className="font-weight-bolder">
                                    {formatAmount(defaultTo(dashboard?.totalAmount, 0))}
                                </h3>
                                <span className="mb-0">Total amount today</span>
                            </div>
                        </div>
                    </div>
                    <TopSellingProductsTable products={dashboard?.topSellingProducts} />
                    {dashboard?.loggedInCashiers && <CashiersTable cashiers={dashboard?.loggedInCashiers} />}
                </div>
            </div>
        </DashboardWrapper>
    );
};

export default withRouter(Dashboard);
