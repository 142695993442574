import { all, call, put, takeEvery } from 'redux-saga/effects';
import { REPORT_ACTIONS, setReportAction, IFetchZReport, IEmailZReportPDF, setTimeTrackingReportAction, setReceiptsAction, IFetchReceipts, IFetchCashiers, setCashiersAction, IFetchTimeTrackingReport, IFetchProducts, setProductsAction } from '../actions/ReportActions';
import { API_ROUTES } from '../constants/routes';
import { IHTTPResponse } from '../types/General';
import { getFetchZReportQueryParamsFromCriteria, getFetchTimeTrackingReportQueryParamsFromCriteria } from '../helpers/ReportHelper';
import { setNotificationAction } from '../actions/ApplicationActions';
import { NOTIFICATION_TYPE, HTTP_METHOD } from '../constants/general';
import { ApiService } from './APISaga';
import { defaultTo } from 'lodash';
import i18n from '../i18n';

const ReportSaga = function* () {
    yield all([
        takeEvery(REPORT_ACTIONS.FETCH_Z_REPORT, fetchZReport),
        takeEvery(REPORT_ACTIONS.EMAIL_Z_REPORT_PDF, emailReportPDF),
        takeEvery(REPORT_ACTIONS.FETCH_TIME_TRACKING_REPORT, fetchTimeTrackingReport),
        takeEvery(REPORT_ACTIONS.FETCH_RECEIPTS, fetchReceipts),
        takeEvery(REPORT_ACTIONS.FETCH_CASHIERS, fetchCashiers),
        takeEvery(REPORT_ACTIONS.FETCH_PRODUCTS, fetchProducts),
    ])
}

const fetchCashiers = function* (action: IFetchCashiers) {
    const fetchCashiersResponse: IHTTPResponse = yield call(ApiService, { method: HTTP_METHOD.GET, url: `${API_ROUTES.CASHIERS}?page=${defaultTo(action.payload.page, 1)}` });

    if (fetchCashiersResponse) {
        yield put(setCashiersAction(fetchCashiersResponse.data));
    }
};

const fetchReceipts = function* (action: IFetchReceipts) {
    const { page, filterCriteria } = action.payload;

    // might rethink this, make generic function to handle this
    let queryParams = `?page=${defaultTo(page, 1)}`;
    if (filterCriteria?.dateFilter) queryParams = `${queryParams}&Date=${filterCriteria?.dateFilter}`;
    if (filterCriteria?.searchTerm) queryParams = `${queryParams}&searchTerm=${filterCriteria?.searchTerm}`;

    const fetchReceiptsResponse: IHTTPResponse = yield call(
        ApiService,
        { method: HTTP_METHOD.GET, url: `${API_ROUTES.RECEIPTS}${queryParams}` },
    );

    if (fetchReceiptsResponse) {
        yield put(setReceiptsAction(fetchReceiptsResponse.data));
    }
};

const fetchZReport = function* (action: IFetchZReport) {
    const { filterCriteria, page } = action.payload;

    const fetchZReportResponse: IHTTPResponse = yield call(ApiService, { method: HTTP_METHOD.GET, url: `${API_ROUTES.Z_REPORT}${getFetchZReportQueryParamsFromCriteria(filterCriteria, page)}` });

    if (fetchZReportResponse) {
        yield put(setReportAction(fetchZReportResponse.data));
    }

};

const emailReportPDF = function* (action: IEmailZReportPDF) {
    const { destinationUrl } = action.payload;

    const emailReportPDFResponse: IHTTPResponse = yield call(ApiService, { method: HTTP_METHOD.GET, url: destinationUrl });

    if (emailReportPDFResponse) {
        yield put(setNotificationAction({
            type: NOTIFICATION_TYPE.SUCCESS,
            content: i18n.t("reportEmailSent"),
        }));
    }

};


const fetchTimeTrackingReport = function* (action: IFetchTimeTrackingReport) {
    const { filterCriteria, page } = action.payload;
    const fetchTimeTrackingResponse: IHTTPResponse = yield call(ApiService, { method: HTTP_METHOD.GET, url: `${API_ROUTES.TIME_TRACKING}${getFetchTimeTrackingReportQueryParamsFromCriteria(filterCriteria, page)}` });

    if (fetchTimeTrackingResponse) {
        yield put(setTimeTrackingReportAction(fetchTimeTrackingResponse.data));
    }

}

const fetchProducts = function* (action: IFetchProducts) {
    const { fromDate, toDate } = action.payload;
    const queryParams = fromDate && fromDate ? `?fromDate=${fromDate}&toDate=${toDate}` : "";
    const fetchProductsAction: IHTTPResponse = yield call(
        ApiService,
        { method: HTTP_METHOD.GET, url: `${API_ROUTES.PRODUCTS}${queryParams}` },
    );

    if (fetchProductsAction) {
        yield put(setProductsAction(fetchProductsAction.data));
    }

}

export { ReportSaga };

