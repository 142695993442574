import { GENERIC_CRUD_ACTIONS, IAllItemActions } from "../actions/GenericCRUDActions";

interface IUserData {
    // TODO IAPIData
    resellers: any;
    uploadedImagePath: string | null;
}

const defaultState = {
    resellers: null,
    uploadedImagePath: null,
};

export default (state: IUserData = defaultState, action: IAllItemActions) => {
    switch (action.type) {
        case GENERIC_CRUD_ACTIONS.SET_ITEMS:
            return { ...state, [action.payload.itemLabel]: action.payload.item }
        case GENERIC_CRUD_ACTIONS.SET_UPLOADED_IMAGE:
            return { ...state, uploadedImagePath: action.payload.url }

        default:
            return state;

    }
};
