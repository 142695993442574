import React from "react";
import ReactDOM from "react-dom";
import "../node_modules/react-confirm-alert/src/react-confirm-alert.css";
import "../src/assets/glyphicons/glyphicons.css";
import "../src/assets/font-awesome/css/font-awesome.min.css";
import "../src/assets/material-design-icons/material-design-icons.css";
import "rsuite/dist/styles/rsuite-default.css";
import "../src/assets/bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/styles/font.css";
import "./styles/index.css";
import "./styles/app.css";
import App from "./App";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import "./i18n";
import { AppRouter } from "./router";
import Auth0ProviderWrap from "./components/Auth0ProviderWrap";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


if(process.env.REACT_APP_ENABLE_SENTRY == 'true') {
    Sentry.init({
        dsn: "https://e3fef0b72b1e407e9f63364c5cb4de5b@o491922.ingest.sentry.io/5578367",
        autoSessionTracking: true,
        integrations: [
            new Integrations.BrowserTracing(),
        ],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
        environment: process.env.REACT_APP_ENVIRONMENT || 'N/A'
    });
}



ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <AppRouter>
                <Auth0ProviderWrap>
                    <App />
                </Auth0ProviderWrap>
            </AppRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
