import { get, map, upperFirst, includes } from "lodash";
import * as React from "react";
import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { deleteItemAction, fetchItemsAction } from "../actions/GenericCRUDActions";
import DashboardWrapper from "../components/DashboardWrapper";
import { FormattedGenericItemValue } from "../components/FormattedGenericItemValue";
import { ListHeader } from "../components/ListHeader";
import { ManageItemModal } from "../components/modals/ManageItemModal";
import { ManageLicenseModal } from "../components/modals/ManageLicenseModal";
import Input from "../components/_styled/Input";
import { GENERIC_CRUD_ITEM, GENERIC_ITEMS_METADATA, moneyRelatedAmountLabels, ROLE } from "../constants/general";
import { useDebounce } from "../lib/hooks/Debounce";
import { getItems } from "../selectors/ItemSelector";
import { getUserRole } from "../helpers/UserHelper";
import { DownloadButton } from "../components/common/DownloadButton";
import { API_ROUTES } from "../constants/routes";

const Items: React.FC = () => {
    const { itemLabel } = useParams();

    const dispatch = useDispatch();
    const items = useSelector(getItems(itemLabel));
    const userRole = getUserRole();
    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [editableItem, setEditableItem] = useState<null>();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    React.useEffect(() => {
        setIsModalOpen(false);
    }, [items]);

    React.useEffect(() => {
        if (debouncedSearchTerm.length > 2 || !debouncedSearchTerm) {
            dispatch(fetchItemsAction(itemLabel, searchTerm));
        }
    }, [debouncedSearchTerm]);

    const deleteItem = (itemId: number) => {
        confirmAlert({
            message: `${t("confirmDelete")} ${GENERIC_ITEMS_METADATA[itemLabel].singular}?`,
            buttons: [
                {
                    label: t("yes"),
                    onClick: () => {
                        dispatch(deleteItemAction(itemLabel, itemId));
                    },
                },
                {
                    label: t("no"),
                    onClick: () => {},
                },
            ],
        });
    };

    const hasAdminPermission =
        (userRole === ROLE.SUPER_ADMIN && GENERIC_ITEMS_METADATA[itemLabel].contextualItem) ||
        !GENERIC_ITEMS_METADATA[itemLabel].contextualItem;
    return (
        <DashboardWrapper>
            <div className="box">
                <ListHeader title={GENERIC_ITEMS_METADATA[itemLabel].upper} totalListItems={items?.totalItems}>
                    {!GENERIC_ITEMS_METADATA[itemLabel].readOnly && hasAdminPermission && (
                        <button
                            className="btn primary pointer pull-right btn-sm ml-2"
                            onClick={() => {
                                setIsModalOpen(true);
                                setEditableItem(null);
                            }}
                        >
                            + {t("addNew")} {GENERIC_ITEMS_METADATA[itemLabel].singularUpper}
                        </button>
                    )}
                    {GENERIC_ITEMS_METADATA[itemLabel].searchable && (
                        <Input
                            sm
                            type="text"
                            className="form-control rounded-lg"
                            placeholder={`${t("search")} ${GENERIC_ITEMS_METADATA[itemLabel].upper}`}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    )}
                </ListHeader>

                <div className="box-divider m-0"></div>
                <div className="table-responsive">
                    {items?.results.length === 0 && (
                        <div className="alert alert-warning" role="alert">
                            {t("no")} {upperFirst(itemLabel)} {t("dataToDisplay")}.
                        </div>
                    )}
                    {items?.results && items?.results.length > 0 && (
                        <>
                            <table className="table table-striped b-t">
                                <thead>
                                    <tr>
                                        {map(
                                            get(GENERIC_ITEMS_METADATA[itemLabel], "tableColumnLabels", []),
                                            (label: string, index) => (
                                                <th
                                                    key={index}
                                                    className={
                                                        includes(
                                                            moneyRelatedAmountLabels,
                                                            get(
                                                                GENERIC_ITEMS_METADATA[itemLabel],
                                                                "tableColumnItemKeys",
                                                                []
                                                            )[index]
                                                        )
                                                            ? "text-right"
                                                            : ""
                                                    }
                                                >
                                                    {label}
                                                </th>
                                            )
                                        )}
                                        {!GENERIC_ITEMS_METADATA[itemLabel].readOnly && (
                                            <>
                                                <th></th>
                                                {/* Option only allowed for stores */}
                                                {hasAdminPermission && itemLabel === GENERIC_CRUD_ITEM.STORES && (
                                                    <th></th>
                                                )}
                                                {/* Option for contextual items (clients and stores) */}
                                                {hasAdminPermission && <th></th>}
                                            </>
                                        )}
                                        {map(GENERIC_ITEMS_METADATA[itemLabel].additionalActions, (item, key) => (
                                            <th key={key}></th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {map(items?.results, (item, index) => {
                                        const downloadCloudConfigUrl = `${process.env.REACT_APP_API_ROOT_URL}${
                                            API_ROUTES.STORES
                                        }/${get(
                                            item,
                                            GENERIC_ITEMS_METADATA[itemLabel].id,
                                            itemLabel
                                        )}/cloud-config/download`;
                                        return (
                                            <tr key={index}>
                                                {map(
                                                    get(GENERIC_ITEMS_METADATA[itemLabel], "tableColumnItemKeys", []),
                                                    (key: string, i) => (
                                                        <td key={i}>
                                                            <FormattedGenericItemValue
                                                                items={items}
                                                                item={item}
                                                                itemKey={key}
                                                                index={index}
                                                            />
                                                        </td>
                                                    )
                                                )}
                                                {!GENERIC_ITEMS_METADATA[itemLabel].readOnly && (
                                                    <>
                                                        <td>
                                                            <button
                                                                onClick={() => {
                                                                    setIsModalOpen(true);
                                                                    setEditableItem(item);
                                                                }}
                                                                className="btn btn-sm btn-outline b-primary text-primary"
                                                            >
                                                                {t("viewEdit")}
                                                            </button>
                                                        </td>
                                                        {hasAdminPermission && itemLabel === GENERIC_CRUD_ITEM.STORES && (
                                                            <td>
                                                                <DownloadButton
                                                                    className="b-info text-info"
                                                                    downloadUrl={downloadCloudConfigUrl}
                                                                    buttonName="downloadCloudConfig"
                                                                    fileName="cloudConfig.zip"
                                                                />
                                                            </td>
                                                        )}
                                                        {hasAdminPermission && (
                                                            <td>
                                                                <button
                                                                    onClick={() =>
                                                                        deleteItem(
                                                                            get(
                                                                                item,
                                                                                GENERIC_ITEMS_METADATA[itemLabel].id,
                                                                                itemLabel
                                                                            )
                                                                        )
                                                                    }
                                                                    className="btn btn-sm btn-outline b-danger text-danger"
                                                                >
                                                                    {t("delete")}
                                                                </button>
                                                            </td>
                                                        )}
                                                    </>
                                                )}
                                                {GENERIC_ITEMS_METADATA[itemLabel].additionalActions && (
                                                    <>
                                                        {map(
                                                            GENERIC_ITEMS_METADATA[itemLabel].additionalActions,
                                                            (Action, index) => {
                                                                return (
                                                                    <td key={index}>
                                                                        <Action item={item} />
                                                                    </td>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                )}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </>
                    )}
                </div>
            </div>
            {/* Might rethink this approach */}
            {itemLabel === GENERIC_CRUD_ITEM.LICENSES && (
                <ManageLicenseModal editableItem={editableItem} isOpen={isModalOpen} toggleOpenModal={setIsModalOpen} />
            )}
            {itemLabel !== GENERIC_CRUD_ITEM.LICENSES && (
                <ManageItemModal
                    itemLabel={itemLabel}
                    editableItem={editableItem}
                    isOpen={isModalOpen}
                    toggleOpenModal={setIsModalOpen}
                />
            )}
        </DashboardWrapper>
    );
};

export { Items };
