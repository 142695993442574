import { all, call, put, takeEvery } from 'redux-saga/effects';
import { APPLICATION_ACTIONS, IFetchDashboard, setDashboardDataAction } from '../actions/ApplicationActions';
import { HTTP_METHOD } from '../constants/general';
import { API_ROUTES } from '../constants/routes';
import { IHTTPResponse } from '../types/General';
import { ApiService } from './APISaga';

const ApplicationSaga = function* () {
    yield all([
        takeEvery(APPLICATION_ACTIONS.FETCH_DASHBOARD_DATA, fetchDashboardData),
    ])
}

const fetchDashboardData = function* (action: IFetchDashboard) {
    const { fromDate, toDate } = action.payload;
    try {
        const fetchDashboardDataResponse: IHTTPResponse = yield call(
            ApiService,
            {
                method: HTTP_METHOD.GET,
                url: `${API_ROUTES.DASHBOARD}?fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`,
            }
        );

        const widgetsResponse: IHTTPResponse = yield call(
            ApiService,
            {
                method: HTTP_METHOD.GET,
                url: `${API_ROUTES.DASHBOARD}/widgets`,
            }
        );

        const dashboardTodayResponse: IHTTPResponse = yield call(
            ApiService,
            {
                method: HTTP_METHOD.GET,
                url: `${API_ROUTES.DASHBOARD}/today`,
            }
        );

        yield put(setDashboardDataAction({...fetchDashboardDataResponse.data, ...widgetsResponse.data}));
    } catch (e) {
        console.log(e);
    }

};

export { ApplicationSaga };

