import { IReportActions, REPORT_ACTIONS } from "../actions/ReportActions";
import { IReportResponse, IProductCategories } from "../types/Report";

interface IReportsData {
    zReport: IReportResponse | null;
    timeTrackingReport: IReportResponse | null;
    receipts: IReportResponse | null;
    cashiers: IReportResponse | null;
    productCategories: IProductCategories | null;
}

const defaultState = {
    zReport: null,
    timeTrackingReport: null,
    receipts: null,
    cashiers: null,
    productCategories: null,
};


export default (state: IReportsData = defaultState, action: IReportActions) => {
    switch (action.type) {
        case REPORT_ACTIONS.SET_Z_REPORT:
            return { ...state, zReport: action.payload.zReport }
        case REPORT_ACTIONS.SET_TIME_TRACKING_REPORT:
            return { ...state, timeTrackingReport: action.payload.timeTrackingReport }
        case REPORT_ACTIONS.SET_RECEIPTS:
            return { ...state, receipts: action.payload.receipts }
        case REPORT_ACTIONS.SET_CASHIERS:
            return { ...state, cashiers: action.payload.cashiers }
        case REPORT_ACTIONS.SET_PRODUCTS:
            return { ...state, productCategories: action.payload.productCategories }

        default:
            return state;
    }
};
