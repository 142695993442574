import styled from "@emotion/styled";
import moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { DateRangePicker } from "rsuite";
import { DownloadButton } from "../components/common/DownloadButton";
import { EmailButton } from "../components/common/EmailButton";
import { ViewPDF } from "../components/common/ViewPDF";
import DashboardWrapper from "../components/DashboardWrapper";
import { ListHeader } from "../components/ListHeader";
import { API_ROUTES } from "../constants/routes";
import { RangePickerCustomRanges } from "../constants/general";

const PDFWrap = styled("div")({
    padding: 20,
});

const Period: React.FC = () => {
    const { t } = useTranslation();
    const [fromDate, setFromDate] = React.useState<string>();
    const [toDate, setToDate] = React.useState<string>();
    const [isViewPDFErrored, setIsViewPDFErrored] = React.useState<boolean>(false);

    const onChangeDateRange = (value: Array<any>) => {
        setFromDate(value[0] ? moment(value[0]).toISOString() : undefined);
        setToDate(value[1] ? moment(value[1]).toISOString() : undefined);
    };

    const queryParams = fromDate ? `fromDate=${fromDate}&toDate=${toDate}` : "";
    const pdfUrl = `${process.env.REACT_APP_API_ROOT_URL}${API_ROUTES.PERIOD_REPORTS}/download`;

    React.useEffect(() => {
        setIsViewPDFErrored(false);
    }, [fromDate, toDate]);

    const shouldShowResults = !isViewPDFErrored && fromDate && toDate;

    return (
        <DashboardWrapper>
            <div className="box period-outer">
                <ListHeader title="Period Report"></ListHeader>

                <div className="p-x-md p-y-lg row">
                    <DateRangePicker
                        className="col-sm-4 col-xs-12 datepicker"
                        style={{ width: 320 }}
                        onChange={onChangeDateRange}
                        onClean={() => {
                            setFromDate(undefined);
                            setToDate(undefined);
                            setIsViewPDFErrored(false);
                        }}
                        placeholder={t("dateRangeFilter")}
                        ranges={RangePickerCustomRanges as any}
                    />
                    {shouldShowResults && (
                        <div className="col-sm-6 col-xs-12 period-action-buttons">
                            <DownloadButton fileName="period-report.pdf" downloadUrl={`${pdfUrl}?${queryParams}`} />
                            <EmailButton
                                url={`${process.env.REACT_APP_API_ROOT_URL}${API_ROUTES.PERIOD_REPORTS}/email`}
                                queryParams={queryParams}
                            >
                                {t("emailPDF")}
                            </EmailButton>
                        </div>
                    )}
                </div>

                <PDFWrap>
                    {fromDate && toDate && (
                        <ViewPDF url={`${pdfUrl}?preview=true&${queryParams}`} onError={setIsViewPDFErrored} />
                    )}
                </PDFWrap>
            </div>
        </DashboardWrapper>
    );
};

export { Period };
