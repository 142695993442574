import * as React from "react";
import { useTranslation } from "react-i18next";
import { map } from "lodash";
import { IProduct } from "../../types/Report";
import { ProductTotals } from "./ProductTotals";
import { FormattedAmount } from "../FormattedAmount";

interface IProps {
    products: IProduct[];
    totals: IProduct;
    groupTotal?: IProduct;
}

const ProductsTable: React.FC<IProps> = ({ products, groupTotal, totals }) => {
    const { t } = useTranslation();

    return (
        <div className="table-responsive">
            <table className="table b-t">
                <thead>
                    <tr className="total-product-tr">
                        <th>{t("quantity")}</th>
                        <th>{t("name")}</th>
                        <th className="text-right">{t("brutto")}</th>
                        <th className="text-right">{t("vat")}</th>
                        <th className="text-right">{t("netto")}</th>
                    </tr>
                </thead>
                <tbody>
                    {map(products, (product, index) => (
                        <tr key={index}>
                            <td className="product-td">{product.quantity}</td>
                            <td className="product-td">{product.productName}</td>
                            <td className="product-td">
                                <FormattedAmount amount={product.brutto} />
                            </td>
                            <td className="product-td">
                                <FormattedAmount amount={product.vat} />
                            </td>
                            <td className="product-td">
                                <FormattedAmount amount={product.netto} />
                            </td>
                        </tr>
                    ))}
                    <ProductTotals totals={totals} />
                    {groupTotal && (
                        <>
                            <tr className="spacer">&nbsp;</tr>
                            <ProductTotals totals={groupTotal} className="total-product-tr group-total" />
                        </>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export { ProductsTable };
