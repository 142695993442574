import styled from "@emotion/styled";

const Button = styled("button")({}, (props: any) => {
    const cursor = props.disabled ? "not-allowed" : "pointer";
    const opacity = props.disabled ? 0.8 : 1;
    return {
        cursor,
        opacity,
    };
});

export default Button;
