import { IUser, IRole } from "../types/User";

enum USER_ACTIONS {

    // users
    FETCH_USERS = "USER_ACTIONS::FETCH_USERS",
    SET_USERS = "USER_ACTIONS::SET_USERS",

    // new user
    ADD_NEW_USER = "USER_ACTIONS::ADD_NEW_USER",

    // single user
    SET_TARGET_USER = "USER_ACTIONS::SET_TARGET_USER",
    GET_USER_BY_ID = "USER_ACTIONS::GET_USER_BY_ID",
    EDIT_USER = "USER_ACTIONS::EDIT_USER",
    SET_CURRENTLY_LOGGED_IN_USER = "USER_ACTIONS::SET_CURRENTLY_LOGGED_IN_USER",

    // ROLE
    FETCH_USER_ROLES = "USER_ACTIONS::FETCH_USER_ROLES",
    SET_USER_ROLES = "USER_ACTIONS::SET_USER_ROLES",
}

interface IFetchUsers {
    type: typeof USER_ACTIONS.FETCH_USERS
    payload: {}
}
const fetchUsersAction = (): IFetchUsers => {
    return {
        type: USER_ACTIONS.FETCH_USERS,
        payload: {},
    }
}

interface ISetUsers {
    type: typeof USER_ACTIONS.SET_USERS
    payload: {
        users: IUser[],
    }
}
const setUsersAction = (users: IUser[]): ISetUsers => {
    return {
        type: USER_ACTIONS.SET_USERS,
        payload: {
            users,
        },
    }
}

interface IAddNewUser {
    type: typeof USER_ACTIONS.ADD_NEW_USER
    payload: {
        user: IUser,
        history: any,
    }
}
const addNewUserAction = (user: IUser, history: any): IAddNewUser => {
    return {
        type: USER_ACTIONS.ADD_NEW_USER,
        payload: {
            user,
            history,
        },
    }
}

interface IGetUserById {
    type: typeof USER_ACTIONS.GET_USER_BY_ID
    payload: {
        userId: string,
    }
}
const getUserByIdAction = (userId: string): IGetUserById => {
    return {
        type: USER_ACTIONS.GET_USER_BY_ID,
        payload: {
            userId,
        },
    }
}

interface ISetTargetUser {
    type: typeof USER_ACTIONS.SET_TARGET_USER
    payload: {
        user: IUser,
    }
}
const setTargetUserAction = (user: IUser): ISetTargetUser => {
    return {
        type: USER_ACTIONS.SET_TARGET_USER,
        payload: {
            user,
        },
    }
}

interface IEditUser {
    type: typeof USER_ACTIONS.EDIT_USER
    payload: {
        user: IUser,
        history: any,
    }
}
const editUserAction = (user: IUser, history: any): IEditUser => {
    return {
        type: USER_ACTIONS.EDIT_USER,
        payload: {
            user,
            history,
        },
    }
}

interface IFetchUserRoles {
    type: typeof USER_ACTIONS.FETCH_USER_ROLES
    payload: {}
}
const fetchUserRolesAction = (): IFetchUserRoles => {
    return {
        type: USER_ACTIONS.FETCH_USER_ROLES,
        payload: {},
    }
}

interface ISetUserRoles {
    type: typeof USER_ACTIONS.SET_USER_ROLES
    payload: {
        roles: IRole[],
    }
}
const setUserRolesAction = (roles: IRole[]): ISetUserRoles => {
    return {
        type: USER_ACTIONS.SET_USER_ROLES,
        payload: {
            roles,
        },
    }
}

interface ISetCurrentlyLoggedInUser {
    type: typeof USER_ACTIONS.SET_CURRENTLY_LOGGED_IN_USER
    payload: {
        user: IUser,
    }
}
const setCurrentlyLoggedInUser = (user: IUser): ISetCurrentlyLoggedInUser => {
    return {
        type: USER_ACTIONS.SET_CURRENTLY_LOGGED_IN_USER,
        payload: {
            user,
        },
    }
}

export type IAllUserActions =
    | ISetUsers
    | IAddNewUser
    | IGetUserById
    | ISetTargetUser
    | IFetchUserRoles
    | ISetUserRoles
    | IEditUser
    | ISetCurrentlyLoggedInUser
    | IFetchUsers;

export type {
    ISetUsers,
    IAddNewUser,
    ISetTargetUser,
    IGetUserById,
    IFetchUserRoles,
    IFetchUsers,
    IEditUser,
    ISetUserRoles,
    ISetCurrentlyLoggedInUser,
}

export {
    fetchUsersAction,
    setUsersAction,
    addNewUserAction,
    getUserByIdAction,
    setTargetUserAction,
    fetchUserRolesAction,
    setUserRolesAction,
    editUserAction,
    setCurrentlyLoggedInUser,
    USER_ACTIONS,
}
