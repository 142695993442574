import { IAPIData } from "../types/General";

enum GENERIC_CRUD_ACTIONS {
    FETCH_ITEMS = "GENERIC_CRUD_ACTIONS::FETCH_ITEMS",
    SET_ITEMS = "GENERIC_CRUD_ACTIONS::SET_ITEMS",
    ADD_NEW_ITEM = "GENERIC_CRUD_ACTIONS::ADD_NEW_ITEM",
    SET_TARGET_ITEM = "GENERIC_CRUD_ACTIONS::SET_TARGET_ITEM",
    GET_ITEM_BY_ID = "GENERIC_CRUD_ACTIONS::GET_ITEM_BY_ID",
    EDIT_ITEM = "GENERIC_CRUD_ACTIONS::EDIT_ITEM",
    DELETE_ITEM = "GENERIC_CRUD_ACTIONS::DELETE_ITEM",
    UPLOAD_IMAGE = "GENERIC_CRUD_ACTIONS::UPLOAD_IMAGE",
    SET_UPLOADED_IMAGE = "GENERIC_CRUD_ACTIONS::SET_UPLOADED_IMAGE",
}

interface IFetchItems {
    type: typeof GENERIC_CRUD_ACTIONS.FETCH_ITEMS
    payload: {
        itemLabel: string;
        searchTerm?: string;
    }
}
const fetchItemsAction = (itemLabel: string, searchTerm?: string): IFetchItems => {
    return {
        type: GENERIC_CRUD_ACTIONS.FETCH_ITEMS,
        payload: {
            itemLabel,
            searchTerm
        },
    }
}

interface ISetItems {
    type: typeof GENERIC_CRUD_ACTIONS.SET_ITEMS
    payload: {
        itemLabel: string;
        item: IAPIData;
    }
}
const setItemsAction = (item: IAPIData, itemLabel: string): ISetItems => {
    return {
        type: GENERIC_CRUD_ACTIONS.SET_ITEMS,
        payload: {
            itemLabel,
            item,
        },
    }
}

interface IAddNewItem {
    type: typeof GENERIC_CRUD_ACTIONS.ADD_NEW_ITEM
    payload: {
        item: any,
        itemLabel: string;
    }
}
const addNewItemAction = (item: any, itemLabel: string): IAddNewItem => {
    return {
        type: GENERIC_CRUD_ACTIONS.ADD_NEW_ITEM,
        payload: {
            item,
            itemLabel,
        },
    }
}

interface IUploadImage {
    type: typeof GENERIC_CRUD_ACTIONS.UPLOAD_IMAGE
    payload: {
        image: any,
    }
}
const uploadImageAction = (image: any): IUploadImage => {
    return {
        type: GENERIC_CRUD_ACTIONS.UPLOAD_IMAGE,
        payload: {
            image,
        },
    }
}

interface ISetUploadedImage {
    type: typeof GENERIC_CRUD_ACTIONS.SET_UPLOADED_IMAGE
    payload: {
        url: string | null,
    }
}
const setUploadedImageAction = (url: string | null): ISetUploadedImage => {
    return {
        type: GENERIC_CRUD_ACTIONS.SET_UPLOADED_IMAGE,
        payload: {
            url,
        },
    }
}

interface IDeleteItem {
    type: typeof GENERIC_CRUD_ACTIONS.DELETE_ITEM
    payload: {
        itemId: any,
        itemLabel: string;
    }
}
const deleteItemAction = (itemLabel: string, itemId: number): IDeleteItem => {
    return {
        type: GENERIC_CRUD_ACTIONS.DELETE_ITEM,
        payload: {
            itemId,
            itemLabel,
        },
    }
}

interface IEditItem {
    type: typeof GENERIC_CRUD_ACTIONS.EDIT_ITEM
    payload: {
        item: any,
        itemId: number,
        itemLabel: string,
    }
}
const editItemAction = (item: any, itemId: number, itemLabel: string): IEditItem => {
    return {
        type: GENERIC_CRUD_ACTIONS.EDIT_ITEM,
        payload: {
            item,
            itemId,
            itemLabel,
        },
    }
}

export type IAllItemActions =
    | ISetItems
    | IAddNewItem
    | IEditItem
    | IDeleteItem
    | IUploadImage
    | ISetUploadedImage
    | IFetchItems;

export type {
    IFetchItems,
    ISetItems,
    IAddNewItem,
    IDeleteItem,
    IUploadImage,
    IEditItem,
    ISetUploadedImage,
}

export {
    fetchItemsAction,
    setItemsAction,
    addNewItemAction,
    deleteItemAction,
    editItemAction,
    uploadImageAction,
    setUploadedImageAction,
    GENERIC_CRUD_ACTIONS,
}
