

enum ContextActions {
    FETCH_CONTEXT_LIST = "ContextActions::FETCH_CONTEXT_LIST",
    SET_CONTEXT_LIST = "ContextActions::SET_CONTEXT_LIST",
}

// TODO: move this to the ApplicationActions
interface IFetchContextList {
    type: typeof ContextActions.FETCH_CONTEXT_LIST
    payload: {}
}
const fetchContextListAction = (): IFetchContextList => {
    return {
        type: ContextActions.FETCH_CONTEXT_LIST,
        payload: {},
    }
}

interface ISetContextList {
    type: typeof ContextActions.SET_CONTEXT_LIST
    payload: {
        contextList: any,
    }
}
const setContextListAction = (contextList: any): ISetContextList => {
    return {
        type: ContextActions.SET_CONTEXT_LIST,
        payload: {
            contextList,
        },
    }
}

export type IAllClientActions =
    | ISetContextList
    | IFetchContextList;

export type {
    ISetContextList,
    IFetchContextList,
};

export {
    fetchContextListAction,
    setContextListAction,
    ContextActions,
};
