import axios, { AxiosInstance } from "axios";
import store from "../store";
import { set } from "lodash";

const API: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_ROOT_URL,
    headers: {
        Authorization: {
            toString() {
                return `Bearer ${localStorage.getItem('token')}`
            }
        },
        Accept: "application/json",
        "Content-Type": "application/json",
    }
});

API.interceptors.request.use((response) => {
    const { clientId, storeId } = store.getState().app;
    if (clientId) set(response.headers, "x-client-id", clientId);
    if (storeId) set(response.headers, "x-store-id", storeId);
    return response;
});

API.interceptors.response.use((response) => {
    return response;
}, (error) => {
    const errorResponse = error.response;
    return Promise.resolve(errorResponse);
});

export default API;
