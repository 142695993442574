import React from "react";
import { Switch, Route, Redirect } from "react-router";
import { ROUTES } from "./constants/routes";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import PrivateRoute from "./components/PrivateRoute";
import Users from "./pages/users/Users";
import { ZReport } from "./pages/ZReport";
import { TimeTracking } from "./pages/TimeTracking";
import { Notifications } from "./components/Notifications";
import { Receipts } from "./pages/Receipts";
import { Items } from "./pages/Items";
import { Backup } from "./pages/Backup";
import { Products } from "./pages/Products";
import { useSelector } from "react-redux";
import { getSideMenuState } from "./selectors/ApplicationSelector";
import { Period } from "./pages/Period";
import { FutureItems } from "./pages/FutureItems";

function App() {
    const showSideMenu = useSelector(getSideMenuState);
    return (
        <div className="app" id="app">
            <Switch>
                <PrivateRoute path={ROUTES.DASHBOARD} component={Dashboard} exact/>
                <Route path={ROUTES.LOGIN} component={Login} />
                <PrivateRoute path={ROUTES.ZREPORT} component={ZReport} />
                <PrivateRoute path={ROUTES.TIME_TRACKING} component={TimeTracking} />
                <PrivateRoute path={ROUTES.USERS} component={Users} />
                <PrivateRoute path={ROUTES.RECEIPTS} component={Receipts} />
                <PrivateRoute path={ROUTES.PRODUCTS} component={Products} />
                <PrivateRoute path={ROUTES.PERIOD} component={Period} />
                <PrivateRoute path={ROUTES.BACKUP} component={Backup} />
                <PrivateRoute path={ROUTES.ITEMS} component={Items} />
                <PrivateRoute path={ROUTES.FUTURE_ITEMS} component={FutureItems} />
                <Route render={() => <Redirect to={ROUTES.DASHBOARD} />} />
            </Switch>
            <Notifications />
            {showSideMenu && <div className="modal-backdrop show hidden-lg-up"></div>}
        </div>
    );
}

export default App;
