import { map } from "lodash";
import * as React from "react";
import { IProductGroups } from "../../types/Report";
import { ProductsTable } from "../products/ProductsTable";

interface IProps {
    productGroups: IProductGroups;
}

const ProductsGroupsTable: React.FC<IProps> = ({ productGroups }) => {
    return (
        <div className="d-flex flex-column">
            {map(productGroups?.productGroups, (productGroup, index) => (
                <div className="d-flex flex-column product-table box">
                    <h2>
                        {productGroup.productGroupName}{" "}
                        <span className="label primary">{productGroup.products.length}</span>
                    </h2>
                    <ProductsTable
                        products={productGroup.products}
                        totals={productGroup.totals}
                        groupTotal={
                            productGroups?.productGroups.length - 1 === index ? productGroups?.totals : undefined
                        }
                    />
                </div>
            ))}
        </div>
    );
};

export { ProductsGroupsTable };
