import { IReportResponse, IZReportFilter, ITimeTrackingFilter, IProductCategories, IReceiptFilter } from "../types/Report";

enum REPORT_ACTIONS {

    // z actions
    FETCH_Z_REPORT = "REPORT_ACTIONS::FETCH_Z_REPORT",
    SET_Z_REPORT = "REPORT_ACTIONS::SET_Z_REPORT",
    EMAIL_Z_REPORT_PDF = "REPORT_ACTIONS::EMAIL_Z_REPORT_PDF",

    // time tracking actions
    FETCH_TIME_TRACKING_REPORT = "REPORT_ACTIONS::FETCH_TIME_TRACKING_REPORT",
    SET_TIME_TRACKING_REPORT = "REPORT_ACTIONS::SET_TIME_TRACKING_REPORT",

    // receipts
    FETCH_RECEIPTS = "REPORT_ACTIONS::FETCH_RECEIPTS",
    SET_RECEIPTS = "REPORT_ACTIONS::SET_RECEIPTS",

    // cashiers
    FETCH_CASHIERS = "REPORT_ACTIONS::FETCH_CASHIERS",
    SET_CASHIERS = "REPORT_ACTIONS::SET_CASHIERS",

    // products
    FETCH_PRODUCTS = "REPORT_ACTIONS::FETCH_PRODUCTS",
    SET_PRODUCTS = "REPORT_ACTIONS::SET_PRODUCTS",
}

// receipts
interface IFetchReceipts {
    type: typeof REPORT_ACTIONS.FETCH_RECEIPTS
    payload: {
        page: number,
        filterCriteria?: IReceiptFilter,
    }
}
const fetchReceiptsAction = (page: number, filterCriteria?: IReceiptFilter): IFetchReceipts => {
    return {
        type: REPORT_ACTIONS.FETCH_RECEIPTS,
        payload: {
            page,
            filterCriteria,
        },
    }
}

interface ISetReceipts {
    type: typeof REPORT_ACTIONS.SET_RECEIPTS
    payload: {
        receipts: IReportResponse,
    }
}
const setReceiptsAction = (receipts: IReportResponse): ISetReceipts => {
    return {
        type: REPORT_ACTIONS.SET_RECEIPTS,
        payload: {
            receipts,
        },
    }
}

// z report actions
interface IFetchZReport {
    type: typeof REPORT_ACTIONS.FETCH_Z_REPORT
    payload: {
        filterCriteria: IZReportFilter | undefined,
        page?: number;
    }
}
const fetchZReportAction = (filterCriteria?: IZReportFilter, page?: number): IFetchZReport => {
    return {
        type: REPORT_ACTIONS.FETCH_Z_REPORT,
        payload: {
            filterCriteria,
            page,
        },
    }
}

interface ISetZReport {
    type: typeof REPORT_ACTIONS.SET_Z_REPORT
    payload: {
        zReport: IReportResponse,
    }
}
const setReportAction = (zReport: IReportResponse): ISetZReport => {
    return {
        type: REPORT_ACTIONS.SET_Z_REPORT,
        payload: {
            zReport,
        },
    }
}

interface IEmailZReportPDF {
    type: typeof REPORT_ACTIONS.EMAIL_Z_REPORT_PDF
    payload: {
        destinationUrl: string,
    }
}
const emailZReportPDFAction = (
    destinationUrl: string,
): IEmailZReportPDF => {
    return {
        type: REPORT_ACTIONS.EMAIL_Z_REPORT_PDF,
        payload: {
            destinationUrl,
        },
    }
}

// time tracking report actions
interface IFetchTimeTrackingReport {
    type: typeof REPORT_ACTIONS.FETCH_TIME_TRACKING_REPORT
    payload: {
        filterCriteria: ITimeTrackingFilter | undefined,
        page?: number,
    }
}
const fetchTimeTrackingReportAction = (filterCriteria?: ITimeTrackingFilter, page?: number): IFetchTimeTrackingReport => {
    return {
        type: REPORT_ACTIONS.FETCH_TIME_TRACKING_REPORT,
        payload: {
            filterCriteria,
            page,
        },
    }
}

interface ISetTimeTrackingReport {
    type: typeof REPORT_ACTIONS.SET_TIME_TRACKING_REPORT
    payload: {
        timeTrackingReport: IReportResponse,
    }
}
const setTimeTrackingReportAction = (timeTrackingReport: IReportResponse): ISetTimeTrackingReport => {
    return {
        type: REPORT_ACTIONS.SET_TIME_TRACKING_REPORT,
        payload: {
            timeTrackingReport,
        },
    }
}

// cashiers
interface IFetchCashiers {
    type: typeof REPORT_ACTIONS.FETCH_CASHIERS
    payload: {
        page?: number;
    }
}
const fetchCashiersAction = (page?: number): IFetchCashiers => {
    return {
        type: REPORT_ACTIONS.FETCH_CASHIERS,
        payload: {
            page,
        },
    }
}

interface ISetCashiers {
    type: typeof REPORT_ACTIONS.SET_CASHIERS
    payload: {
        cashiers: IReportResponse,
    }
}
const setCashiersAction = (cashiers: IReportResponse): ISetCashiers => {
    return {
        type: REPORT_ACTIONS.SET_CASHIERS,
        payload: {
            cashiers,
        },
    }
}

// products
interface IFetchProducts {
    type: typeof REPORT_ACTIONS.FETCH_PRODUCTS
    payload: {
        fromDate: string | undefined,
        toDate: string | undefined,
    }
}
const fetchProductsAction = (fromDate?: string, toDate?: string): IFetchProducts => {
    return {
        type: REPORT_ACTIONS.FETCH_PRODUCTS,
        payload: {
            fromDate,
            toDate,
        },
    }
}
interface ISetProducts {
    type: typeof REPORT_ACTIONS.SET_PRODUCTS
    payload: {
        productCategories: IProductCategories,
    }
}
const setProductsAction = (productCategories: IProductCategories): ISetProducts => {
    return {
        type: REPORT_ACTIONS.SET_PRODUCTS,
        payload: {
            productCategories,
        },
    }
}


export type IReportActions =
    | IEmailZReportPDF
    | ISetZReport
    | IFetchTimeTrackingReport
    | ISetTimeTrackingReport
    | IFetchReceipts
    | ISetReceipts
    | IFetchCashiers
    | ISetCashiers
    | IFetchProducts
    | ISetProducts
    | IFetchZReport;

export type {
    ISetZReport,
    IFetchZReport,
    IEmailZReportPDF,
    IFetchTimeTrackingReport,
    ISetTimeTrackingReport,
    ISetReceipts,
    IFetchReceipts,
    ISetCashiers,
    IFetchCashiers,
    IFetchProducts,
    ISetProducts,
};
export {
    fetchZReportAction,
    setReportAction,
    emailZReportPDFAction,
    fetchTimeTrackingReportAction,
    setTimeTrackingReportAction,
    fetchReceiptsAction,
    setReceiptsAction,
    setCashiersAction,
    fetchCashiersAction,
    fetchProductsAction,
    setProductsAction,
    REPORT_ACTIONS,
};


