import * as React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "rsuite";
import { IModalProps } from "../../types/General";
import Button from "../_styled/Button";
import ReactJson from "react-json-view";

type IProps = {
    syncObject: any;
} & IModalProps;

const ViewSyncDetailsModal: React.FC<IProps> = ({ isOpen, toggleOpenModal, syncObject }) => {
    const { t } = useTranslation();

    return (
        <Modal size="xs" show={isOpen} onHide={() => toggleOpenModal(!isOpen)}>
            <Modal.Header>
                <Modal.Title>{t("syncDetails")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ReactJson displayDataTypes={false} src={syncObject} enableClipboard={false} />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="btn btn-sm ml-2 btn-outline b-primary text-primary"
                    onClick={() => toggleOpenModal(!isOpen)}
                    appearance="subtle"
                >
                    {t("close")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export { ViewSyncDetailsModal };
