import i18n from "../i18n";
import { ROLE, GENERIC_ITEMS_METADATA, GENERIC_CRUD_ITEM, CONTEXT } from "./general";
import { ROUTES } from "./routes";
import { IMenuItem } from "../types/General";

enum MENU_ITEM_TYPE {
    GENERAL = "general",
    SUPER_ADMIN = "superAdmin",
    REPORTS = "reports",
    PROGRAMMING = "programming",
    ORDER_FAKTURA = "orderFaktura",
    SETTINGS = "settings",
}

const MENU_ITEMS: IMenuItem[] = [
    // General
    {
        name: i18n.t("dashboard"),
        icon: "dashboard",
        subset: MENU_ITEM_TYPE.GENERAL,
        link: ROUTES.DASHBOARD,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN],
    },

    // Super admin
    {
        name: i18n.t("users"),
        icon: "account_box",
        subset: MENU_ITEM_TYPE.SUPER_ADMIN,
        link: ROUTES.USERS,
        context: CONTEXT.CLIENT,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN],
    },
    {
        name: GENERIC_ITEMS_METADATA[GENERIC_CRUD_ITEM.RESELLERS].upper,
        icon: GENERIC_ITEMS_METADATA[GENERIC_CRUD_ITEM.RESELLERS].icon,
        subset: MENU_ITEM_TYPE.SUPER_ADMIN,
        context: CONTEXT.CLIENT,
        link: `/items/${GENERIC_CRUD_ITEM.RESELLERS}`,
        accessibleBy: [ROLE.SUPER_ADMIN],
    },
    {
        name: GENERIC_ITEMS_METADATA[GENERIC_CRUD_ITEM.STORES].upper,
        icon: GENERIC_ITEMS_METADATA[GENERIC_CRUD_ITEM.STORES].icon,
        subset: MENU_ITEM_TYPE.SUPER_ADMIN,
        context: CONTEXT.CLIENT,
        link: `/items/${GENERIC_CRUD_ITEM.STORES}`,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN],
    },
    {
        name: GENERIC_ITEMS_METADATA[GENERIC_CRUD_ITEM.CLIENTS].upper,
        icon: GENERIC_ITEMS_METADATA[GENERIC_CRUD_ITEM.CLIENTS].icon,
        subset: MENU_ITEM_TYPE.SUPER_ADMIN,
        context: CONTEXT.CLIENT,
        link: `/items/${GENERIC_CRUD_ITEM.CLIENTS}`,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN],
    },
    {
        name: GENERIC_ITEMS_METADATA[GENERIC_CRUD_ITEM.LICENSES].upper,
        icon: GENERIC_ITEMS_METADATA[GENERIC_CRUD_ITEM.LICENSES].icon,
        subset: MENU_ITEM_TYPE.SUPER_ADMIN,
        link: `/items/${GENERIC_CRUD_ITEM.LICENSES}`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER],
    },
    {
        name: GENERIC_ITEMS_METADATA[GENERIC_CRUD_ITEM.SYNC].upper,
        icon: GENERIC_ITEMS_METADATA[GENERIC_CRUD_ITEM.SYNC].icon,
        subset: MENU_ITEM_TYPE.SUPER_ADMIN,
        context: CONTEXT.CLIENT,
        link: `/items/${GENERIC_CRUD_ITEM.SYNC}`,
        accessibleBy: [ROLE.SUPER_ADMIN],
    },
    {
        name: i18n.t("backup"),
        icon: "backup",
        subset: MENU_ITEM_TYPE.SUPER_ADMIN,
        link: ROUTES.BACKUP,
        accessibleBy: [ROLE.SUPER_ADMIN],
    },

    // Reports
    {
        name: i18n.t("receipts"),
        icon: "receipt_long",
        subset: MENU_ITEM_TYPE.REPORTS,
        link: ROUTES.RECEIPTS,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: i18n.t("zReport"),
        icon: "description",
        subset: MENU_ITEM_TYPE.REPORTS,
        link: ROUTES.ZREPORT,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: i18n.t("timeTracking"),
        icon: "schedule",
        subset: MENU_ITEM_TYPE.REPORTS,
        link: ROUTES.TIME_TRACKING,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: GENERIC_ITEMS_METADATA[GENERIC_CRUD_ITEM.CASHIERS].upper,
        icon: GENERIC_ITEMS_METADATA[GENERIC_CRUD_ITEM.CASHIERS].icon,
        subset: MENU_ITEM_TYPE.REPORTS,
        link: `/items/${GENERIC_CRUD_ITEM.CASHIERS}`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: GENERIC_ITEMS_METADATA[GENERIC_CRUD_ITEM.ORDERS].upper,
        icon: GENERIC_ITEMS_METADATA[GENERIC_CRUD_ITEM.ORDERS].icon,
        subset: MENU_ITEM_TYPE.REPORTS,
        link: `/items/${GENERIC_CRUD_ITEM.ORDERS}`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: GENERIC_ITEMS_METADATA[GENERIC_CRUD_ITEM.GIFT_CARDS].upper,
        icon: GENERIC_ITEMS_METADATA[GENERIC_CRUD_ITEM.GIFT_CARDS].icon,
        subset: MENU_ITEM_TYPE.REPORTS,
        link: `/items/${GENERIC_CRUD_ITEM.GIFT_CARDS}`,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: i18n.t("productsSideMenuItem"),
        icon: "widgets",
        subset: MENU_ITEM_TYPE.REPORTS,
        link: ROUTES.PRODUCTS,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
    {
        name: i18n.t("period"),
        icon: "history_edu",
        subset: MENU_ITEM_TYPE.REPORTS,
        link: ROUTES.PERIOD,
        context: CONTEXT.STORE,
        accessibleBy: [ROLE.SUPER_ADMIN, ROLE.RESELLER, ROLE.CLIENT_ADMIN, ROLE.ACCOUNTANT],
    },
];

export {
    MENU_ITEMS,
    MENU_ITEM_TYPE,
}