import { StoreState } from '../store';
import { createSelector } from "reselect";
import { set, find } from 'lodash';

const getUsers = (state: StoreState) => state.user.usersList;
const getTargetUser = (state: StoreState) => state.user.targetUser;
const getUserRoles = (state: StoreState) => state.user.roles;
const getCurrentlyLoggedInUser = (state: StoreState) => state.user.currentlyLoggedInUser;

// refactor this when time is available, make user roles dictionary
const getFormattedUsers = createSelector(getUsers, getUserRoles, (users, roles) => {
    return users.map((user) => {
        const userCloned = {...user};
        set(userCloned, "role", find(roles, { role: user.role})?.name);
        set(userCloned, "roleId", user.role);
        return userCloned;
    });
});

export { getUsers, getTargetUser, getUserRoles, getCurrentlyLoggedInUser, getFormattedUsers }
