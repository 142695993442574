import * as React from "react";
import { map, orderBy } from "lodash";
import { IDashboard } from "../../types/General";
import { formatAmount } from "../../helpers/GeneralHelper";

const TopSellingProductsTable: React.FC<{ products?: IDashboard["topSellingProducts"] }> = ({ products }) => {
    const productsSorted = orderBy(products, "amount", "desc");
    return (
        <div className="row">
            <div className="col-md-12 pl-1">
                <div className="box p-a-md">
                    <h6 className="m-b-md fw-bold">Top selling products</h6>
                    <table className="table table-striped b-t table-sm">
                        <thead>
                            <tr>
                                <th className="px-0 py-1">Article Name</th>
                                <th className="text-right px-0 py-1">Quantity</th>
                                <th className="text-right px-0 py-1">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {map(productsSorted, (product) => (
                                <tr>
                                    <td className="px-0 py-1">{product.articleName}</td>
                                    <td className="text-right px-0 py-1">{product.quantity}</td>
                                    <td className="text-right px-0 py-1">{formatAmount(product.amount)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export { TopSellingProductsTable };
