import * as React from "react";
import { ViewPDFButton } from "../common/ViewPDFButton";
import { API_ROUTES } from "../../constants/routes";

const PreviewOrderPDF: React.FC<any> = ({ item }) => {
    return (
        <ViewPDFButton previewUrl={`${process.env.REACT_APP_API_ROOT_URL}${API_ROUTES.ORDERS_PDF}/${item.orderId}/download?preview=true`} />
    );
};

export {
    PreviewOrderPDF,
};
