import { all } from 'redux-saga/effects'
import { UserSaga } from './UserSaga';
import { ReportSaga } from './ReportSaga';
import { ContextSaga } from './ContextSaga';
import { GenericCrudSaga } from './GenericCrudSaga';
import { ApplicationSaga } from './ApplicationSaga';

export default function* rootSaga() {
    yield all([
        UserSaga(),
        ReportSaga(),
        ContextSaga(),
        GenericCrudSaga(),
        ApplicationSaga(),
    ]);
}
