enum ROUTES {
    DASHBOARD = "/",
    LOGIN = "/login",
    ZREPORT = "/z-report",
    TIME_TRACKING = "/time-tracking",
    RECEIPTS = "/receipts",
    PRODUCTS = "/products",
    PERIOD = "/period",
    USERS = "/users",
    BACKUP = "/backup",
    ITEMS = "/items/:itemLabel",
    STORES = "items/stores",
    FUTURE_ITEMS =  "/future-items/:itemLabel",
    ADD_NEW_USER = "/users/add-new",
    EDIT_USER_PROFILE = "/users/edit/:userId",
}

enum API_ROUTES {
    API_ROOT = "/api",
    USERS = "/api/Users",
    USERS_ROLES = "/api/Users/roles",

    Z_REPORT = "/api/Reports/z-report",
    Z_REPORT_PDF = "/api/Reports/z-report/",

    ORDERS_PDF = "/api/Orders",

    CLIENTS = "/api/Clients",
    STORES = "/api/Stores",
    RECEIPTS = "/api/Receipts",
    PERIOD_REPORTS = "/api/Reports/period",
    CASHIERS = "/api/Cashiers",
    TIME_TRACKING = "/api/Reports/time-tracking",
    TRANSACTIONS = "/api/Transactions",
    PRODUCTS = "/api/Transactions/reports/products",

    DASHBOARD = "/api/Dashboard",
    IMAGE_UPLOAD = "/api/Infrastructure/images/thumbnail",
}

export {
    ROUTES,
    API_ROUTES,
}