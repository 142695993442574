import { filter, groupBy, includes, map, concat } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, withRouter } from "react-router-dom";
import { CONTEXT } from "../constants/general";
import { MENU_ITEMS } from "../constants/menuItems";
import { ROUTES } from "../constants/routes";
import { getClientId, getStoreId, getSideMenuState } from "../selectors/ApplicationSelector";
import UserProfile from "./UserProfile";
import { getUserRole } from "../helpers/UserHelper";
import { toggleSideMenuAction } from "../actions/ApplicationActions";
import { useRef } from "react";
import useOnClickOutside from "../lib/hooks/useClickOutside";
import { motion } from "framer-motion";
import { futureMenuItems } from "../constants/futureMenuItems";

const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "-100%" },
};

const SideMenu: React.FC = () => {
    const dispatch = useDispatch();
    const clientId = useSelector(getClientId);
    const storeId = useSelector(getStoreId);
    const showSideMenu = useSelector(getSideMenuState);
    const ref = useRef();
    const { t } = useTranslation();

    useOnClickOutside(ref, () => {
        // 992 breaking point for small devices
        if (showSideMenu && window.innerWidth < 992) dispatch(toggleSideMenuAction());
    });

    const userRole = getUserRole();

    const filteredMenuItems = filter(concat(MENU_ITEMS, futureMenuItems), (item) => {
        // filter by user roles
        const doesUserHaveAccess = includes(item.accessibleBy, userRole);

        // filter by selected context
        const doesItemMatchesStoreContextSelected = (storeId && item.context === CONTEXT.STORE) || !storeId;
        const doesItemMatchesClientContextSelected =
            (clientId && item.context === CONTEXT.CLIENT) || !clientId || !!storeId;

        return doesUserHaveAccess && doesItemMatchesStoreContextSelected && doesItemMatchesClientContextSelected;
    });

    const groupedMenuItems = groupBy(filteredMenuItems, "subset");

    return (
        <motion.div
            id="aside"
            // initial="closed"
            // animate={showSideMenu ? "open" : "closed"}
            // variants={variants}
            // transition={{ duration: 0.5 }}
            className={`app-aside modal nav-dropdown ${showSideMenu ? "show" : ""}`}
        >
            <div className="left navside dark dk" data-layout="column" ref={ref as any}>
                <div className="navbar no-radius">
                    <Link className="navbar-brand text-center" to={ROUTES.DASHBOARD}>
                        <img src="/images/logo.png" alt="." className="hide" />
                        <span className="hidden-folded _400 inline">CashOnCash</span>
                    </Link>
                </div>

                {/* User profile */}
                <UserProfile />

                <div className="scroll-wrap">
                    <nav className="scroll nav-active-primary">
                        <ul className="nav">
                            {map(groupedMenuItems, (menuItems, title: string) => (
                                <React.Fragment key={title}>
                                    <li className="nav-header hidden-folded">
                                        <small className="text-muted text-uppercase">{t(title)}</small>
                                    </li>
                                    {map(menuItems, (item, index) => (
                                        <li key={index}>
                                            <NavLink
                                                exact
                                                to={item.link}
                                                activeClassName="active"
                                                onClick={() => dispatch(toggleSideMenuAction())}
                                            >
                                                <span className="nav-icon">
                                                    <i className="material-icons">{item.icon}</i>
                                                </span>
                                                <span className="nav-text">{item.name}</span>
                                            </NavLink>
                                        </li>
                                    ))}
                                </React.Fragment>
                            ))}
                        </ul>
                    </nav>
                </div>
            </div>
        </motion.div>
    );
};

export default withRouter(SideMenu);
