import { get, map, remove } from "lodash";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Modal } from "rsuite";
import { addNewUserAction, editUserAction } from "../../actions/UserActions";
import { passwordLength, ROLE, userNameMinLength } from "../../constants/general";
import { getUserRole } from "../../helpers/UserHelper";
import { AssignStoreClient } from "../../pages/users/AssignStoreClient";
import { getUserRoles } from "../../selectors/UserSelector";
import { IModalProps } from "../../types/General";
import { IUser } from "../../types/User";
import Button from "../_styled/Button";

interface IProps {
    user?: IUser;
}

const ManageUserModal: React.FC<IProps & IModalProps> = ({ user, isOpen, toggleOpenModal }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { register, handleSubmit, errors, setValue } = useForm();
    register("clients");
    register("stores");
    const userRole = getUserRole();
    let userRoles = useSelector(getUserRoles);

    const saveUser = (alteredUser: IUser) => {
        if (user) {
            dispatch(editUserAction({ ...alteredUser, userId: user.userId }, history));
        } else {
            dispatch(addNewUserAction(alteredUser, history));
        }
    };

    const selectContext = (values: Record<string, any>) => {
        for (const contextKey in values) {
            setValue(contextKey, get(values, contextKey));
        }
    };

    remove(userRoles, (role) => {
        return userRole !== ROLE.SUPER_ADMIN && role.name === ROLE.SUPER_ADMIN;
    });

    return (
        <Modal size="xs" show={isOpen} onHide={() => toggleOpenModal(!isOpen)}>
            <Modal.Header>
                <Modal.Title>
                    {!user && <>{t("addNewUser")}</>}
                    {user && <>{t("editUser")}</>}
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(saveUser)}>
                <Modal.Body>
                    <div className="form-group">
                        <label>{t("email")}</label>
                        <input
                            type="email"
                            className="form-control"
                            placeholder={t("email")}
                            name="email"
                            defaultValue={user?.email}
                            ref={register({ required: true })}
                        />
                        {errors && errors.email && (
                            <div className="alert alert-danger p-2 mt-1 text-xs">{t("emailRequired")}.</div>
                        )}
                    </div>
                    <div className="form-group">
                        <label>{t("name")}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder={t("name")}
                            defaultValue={user?.name}
                            ref={register({ required: true, minLength: userNameMinLength })}
                        />
                        {errors && errors.name && (
                            <div className="alert alert-danger p-2 mt-1 text-xs">
                                {t("nameLength", { characters: userNameMinLength })}.
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label>{t("phoneNumber")}</label>
                        <input
                            type="text"
                            name="phoneNumber"
                            className="form-control"
                            placeholder={t("phoneNumber")}
                            defaultValue={user?.phoneNumber}
                            ref={register}
                        />
                    </div>
                    <div className="form-group">
                        <label>{t("assignStoreClient")}</label>
                        <AssignStoreClient
                            onselectContext={(values) => selectContext(values)}
                            context={{
                                clients: user?.clients,
                                stores: user?.stores,
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <label>{t("role")}</label>
                        <div>
                            <select
                                name="role"
                                className="form-control c-select"
                                ref={register({ required: true })}
                                defaultValue={user?.roleId}
                            >
                                {map(userRoles, (role, index) => (
                                    <option key={index} value={role.role}>
                                        {role.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {errors && errors.role && (
                            <div className="alert alert-danger p-2 mt-1 text-xs">{t("roleRequired")}.</div>
                        )}
                    </div>
                    {!user && (
                        <div className="form-group">
                            <label>{t("password")}</label>
                            <input
                                type="password"
                                className="form-control"
                                placeholder={t("password")}
                                name="password"
                                ref={register({
                                    required: true,
                                    minLength: passwordLength,
                                })}
                            />
                            {errors && errors.password && (
                                <>
                                    {errors.password.type === "required" && (
                                        <div className="alert alert-danger p-2 mt-1 text-xs">
                                            {t("passwordRequired")}.
                                        </div>
                                    )}
                                    {errors.password.type === "minLength" && (
                                        <div className="alert alert-danger p-2 mt-1 text-xs">
                                            {t("passLength")} {passwordLength} {t("characters")}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" className="btn btn-sm info">
                        {t("saveUser")}
                    </Button>
                    <Button
                        className="btn btn-sm ml-2 btn-outline b-info text-info"
                        onClick={() => toggleOpenModal(!isOpen)}
                        appearance="subtle"
                    >
                        {t("close")}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export { ManageUserModal };
