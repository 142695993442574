import { IHTTPResponse } from "../types/General";
import API from "../lib/Api";
import { put, call } from "redux-saga/effects";
import { setNotificationAction, setLoadingStateAction } from "../actions/ApplicationActions";
import { NOTIFICATION_TYPE } from "../constants/general";

const ApiService = function* (action: any) {
    // regex for success status codes
    var regEx = /^[2-3][0-9][0-9]$/

    // setting loader to true
    yield put(setLoadingStateAction(true));

    const { method, url, payload, headers } = action;
    const response: IHTTPResponse = yield call(() => (API as any)[method](url, payload, { headers }));

    // setting loader to false
    yield put(setLoadingStateAction(false));

    // if status code is not 2** push error message
    if (!regEx.test(`${response.status}`)) {
        yield put(setNotificationAction({
            type: NOTIFICATION_TYPE.ERROR,
            content: JSON.stringify(response.data, null, 2),
            duration: 15000,
            formatted: true,
        }));
    } else {
        return response;
    }
}

export {
    ApiService,
}