import * as React from "react";
import { withRouter } from "react-router";
import Footer from "./Footer";
import Header from "./Header";
import SideMenu from "./SideMenu";
import { useSelector } from "react-redux";
import { getLoadingState } from "../selectors/ApplicationSelector";
import { Loader } from "rsuite";

const DashboardWrapper: React.FC = ({ children }) => {
    const loading = useSelector(getLoadingState);
    return (
        <>
            <SideMenu />
            <div id="content" className="app-content box-shadow-z0" role="main">
                <Header />
                <div className="app-body" id="view">
                    <div className="padding pa-4">
                        {loading && <Loader size="lg" center />}
                        {children}
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default withRouter(DashboardWrapper);
