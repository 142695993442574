import { USER_ACTIONS, IAllUserActions } from "../actions/UserActions";
import { IUser, IRole } from "../types/User";

interface IUserData {
	usersList: IUser[];
	targetUser: null | IUser;
	roles: IRole[];
	currentlyLoggedInUser: null | IUser;
}

const defaultState = {
	usersList: [],
	roles: [],
	targetUser: null,
	currentlyLoggedInUser: null,
};

export default (state: IUserData = defaultState, action: IAllUserActions) => {
	switch (action.type) {
		case USER_ACTIONS.SET_USERS:
			return { ...state, usersList: action.payload.users }
		case USER_ACTIONS.SET_TARGET_USER:
			return { ...state, targetUser: action.payload.user }
		case USER_ACTIONS.SET_USER_ROLES:
			return { ...state, roles: action.payload.roles }
		case USER_ACTIONS.SET_CURRENTLY_LOGGED_IN_USER:
			return { ...state, currentlyLoggedInUser: action.payload.user }

		default:
			return state;
	}
};
