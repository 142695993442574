import * as React from "react";
import { formatAmount } from "../helpers/GeneralHelper";
import i18n from "../i18n";
import { isNull } from "util";
import { includes, get } from "lodash";
import { moneyRelatedAmountLabels } from "../constants/general";

interface IProps {
    items: any;
    item: any;
    itemKey: string;
    index: string;
}

const FormattedGenericItemValue: React.FC<IProps> = ({ items, item, itemKey, index }) => {
    const formatValue = (items: any, item: any, key: string, index: number | string) => {
        let formattedValue: string | number = get(item, key);
        if (key === "active") {
            return formattedValue ? i18n.t("yes") : i18n.t("no");
        } else if (key === "inUse") {
            return !isNull(get(items, `results.${index}.deviceIdentifier`)) ? i18n.t("yes") : i18n.t("no");
        } else if (includes(moneyRelatedAmountLabels, key)) {
            return formatAmount(formattedValue);
        } else {
            return formattedValue;
        }
    };

    return (
        <span className={includes(moneyRelatedAmountLabels, itemKey) ? "pull-right" : ""}>
            {formatValue(items, item, itemKey, index)}
        </span>
    );
};

export { FormattedGenericItemValue };
