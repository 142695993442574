import { all, call, put, takeEvery } from 'redux-saga/effects';
import { IAddNewUser, IGetUserById, setUserRolesAction, setUsersAction, USER_ACTIONS, setTargetUserAction, fetchUsersAction } from '../actions/UserActions';
import { API_ROUTES, ROUTES } from '../constants/routes';
import { IHTTPResponse } from '../types/General';
import { setNotificationAction } from '../actions/ApplicationActions';
import { NOTIFICATION_TYPE, HTTP_METHOD } from '../constants/general';
import { ApiService } from './APISaga';
import i18n from '../i18n';
import randomColor from 'randomcolor';

const UserSaga = function* () {
    yield all([
        takeEvery(USER_ACTIONS.FETCH_USERS, fetchUsers),
        takeEvery(USER_ACTIONS.ADD_NEW_USER, addNewUser),
        takeEvery(USER_ACTIONS.GET_USER_BY_ID, getUserById),
        takeEvery(USER_ACTIONS.FETCH_USER_ROLES, fetchUserRoles),
        takeEvery(USER_ACTIONS.EDIT_USER, editUser),
    ])
}

const fetchUsers = function* () {
    try {
        const fetchUsersResponse = yield call(ApiService, { method: HTTP_METHOD.GET, url: API_ROUTES.USERS });

        if (fetchUsersResponse) {
            yield put(setUsersAction(fetchUsersResponse.data));
        }

    } catch (error) {
        // throw some error
    }
};

const getUserById = function* (action: IGetUserById) {
    try {
        const { userId } = action.payload;

        const fetchSingleUserResponse: IHTTPResponse = yield call(ApiService, { method: HTTP_METHOD.GET, url: `${API_ROUTES.USERS}/${userId}` });

        if (fetchSingleUserResponse) {
            yield put(setTargetUserAction({ ...fetchSingleUserResponse.data, avatarColor: randomColor({ luminosity: 'light' }) }));
        }

    } catch (error) {
        // throw some error
    }
};

const editUser = function* (action: IAddNewUser) {
    try {
        const { user, history } = action.payload;

        const editUserResponse: IHTTPResponse = yield call(ApiService, { method: HTTP_METHOD.PUT, url: `${API_ROUTES.USERS}/${user.userId}`, payload: user });

        if (editUserResponse) {
            // refresh users list
            yield put(fetchUsersAction());
            history.push(ROUTES.USERS);

            yield put(setNotificationAction({
                type: NOTIFICATION_TYPE.SUCCESS,
                content: i18n.t("editUserMessage"),
            }));
        }
    } catch (error) {
        yield put(setNotificationAction({
            type: NOTIFICATION_TYPE.ERROR,
            content: i18n.t("editUserErrorMessage"),
        }));
    }
};

const addNewUser = function* (action: IAddNewUser) {
    try {
        const { user, history } = action.payload;
        const saveNewUserResponse: IHTTPResponse = yield call(ApiService, { method: HTTP_METHOD.POST, url: API_ROUTES.USERS, payload: user });

        if (saveNewUserResponse) {
            yield put(fetchUsersAction());
            history.push(ROUTES.USERS);

            yield put(setNotificationAction({ type: NOTIFICATION_TYPE.SUCCESS, content: i18n.t("newUserCreated") }));
        }
    } catch (error) {
        yield put(setNotificationAction({
            type: NOTIFICATION_TYPE.ERROR,
            content: i18n.t("createNewUserError") + error,
        }));
    }
};

const fetchUserRoles = function* () {
    try {
        const fetchUserRolesResponse: IHTTPResponse = yield call(ApiService, { method: HTTP_METHOD.GET, url: API_ROUTES.USERS_ROLES });

        if (fetchUserRolesResponse) {
            yield put(setUserRolesAction(fetchUserRolesResponse.data));
        }

    } catch (error) {
        // throw some error
    }
};

export { UserSaga };

