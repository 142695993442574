import * as React from "react";
import { Modal } from "rsuite";
import Button from "../_styled/Button";
import { useDispatch } from "react-redux";
import { emailZReportPDFAction } from "../../actions/ReportActions";
import { REPORT_PDF_DESTINATION_EMAIL } from "../../constants/general";
import { IModalProps } from "../../types/General";
import { useTranslation } from "react-i18next";
import { defaultTo } from "lodash";

type IProps = {
    destinationUrl: string;
    queryParams?: string;
} & IModalProps;

const ReportDestinationEmailModal: React.FC<IProps> = ({ isOpen, toggleOpenModal, destinationUrl, queryParams }) => {
    const existingDestinationEmail = localStorage.getItem(REPORT_PDF_DESTINATION_EMAIL) || "";
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const [destinationEmail, setDestinationEmail] = React.useState<string>(existingDestinationEmail);

    return (
        <Modal size="lg" show={isOpen} onHide={() => toggleOpenModal(!isOpen)}>
            <Modal.Header>
                <Modal.Title>{t("emailReportPDFTitle")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <input
                        type="email"
                        className="form-control"
                        placeholder={t("destinationEmail")}
                        name="email"
                        value={destinationEmail}
                        onChange={(e) => setDestinationEmail(e.target.value)}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="btn btn-sm info"
                    onClick={() => {
                        localStorage.setItem(REPORT_PDF_DESTINATION_EMAIL, destinationEmail);
                        toggleOpenModal(!isOpen);
                        dispatch(
                            emailZReportPDFAction(`${destinationUrl}/${destinationEmail}?${defaultTo(queryParams, "")}`)
                        );
                    }}
                    disabled={destinationEmail === ""}
                >
                    {t("sendReport")}
                </Button>
                <Button
                    className="btn btn-sm ml-2 btn-outline b-info text-info"
                    onClick={() => toggleOpenModal(!isOpen)}
                    appearance="subtle"
                >
                    {t("cancel")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export { ReportDestinationEmailModal };
