import { StoreState } from '../store';
import { createSelector } from 'reselect';
import { reduce, each, set } from 'lodash';

const getContextList = (state: StoreState) => state.context.contextList;

const getStoreClientDictionary = createSelector(getContextList, (contextList) => {
    return reduce(
        contextList,
        (acc: Record<string, string>, val: any) => {
            each(val.stores, (store: any) => {
                set(acc, store.storeId, val.clientId);
            });
            return acc;
        },
        {}
    );
})

export { getContextList, getStoreClientDictionary }
