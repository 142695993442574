import { INotification, IDashboard } from "../types/General";

enum APPLICATION_ACTIONS {
    SET_NOTIFICATION = "APPLICATION_ACTIONS::SET_NOTIFICATION",
    SET_LOADING_STATE = "APPLICATION_ACTIONS::SET_LOADING_STATE",
    SET_STORE_ID = "APPLICATION_ACTIONS::SET_STORE_ID",
    SET_CLIENT_ID = "APPLICATION_ACTIONS::SET_CLIENT_ID",
    TOGGLE_SIDE_MENU = "APPLICATION_ACTIONS::TOGGLE_SIDE_MENU",
    FETCH_DASHBOARD_DATA = "APPLICATION_ACTIONS::FETCH_DASHBOARD_DATA",
    SET_DASHBOARD_DATA = "APPLICATION_ACTIONS::SET_DASHBOARD_DATA",
}

interface ISetNotification {
    type: typeof APPLICATION_ACTIONS.SET_NOTIFICATION
    payload: {
        notification: INotification,
    }
}
const setNotificationAction = (notification: INotification): ISetNotification => {
    return {
        type: APPLICATION_ACTIONS.SET_NOTIFICATION,
        payload: {
            notification,
        },
    }
}

interface ISetLoadingState {
    type: typeof APPLICATION_ACTIONS.SET_LOADING_STATE
    payload: {
        loading: boolean;
    }
}
const setLoadingStateAction = (loading: boolean): ISetLoadingState => {
    return {
        type: APPLICATION_ACTIONS.SET_LOADING_STATE,
        payload: {
            loading,
        },
    }
}

interface IToggleSideMenu {
    type: typeof APPLICATION_ACTIONS.TOGGLE_SIDE_MENU
    payload: {}
}
const toggleSideMenuAction = (): IToggleSideMenu => {
    return {
        type: APPLICATION_ACTIONS.TOGGLE_SIDE_MENU,
        payload: {},
    }
}

interface ISetStoreId {
    type: typeof APPLICATION_ACTIONS.SET_STORE_ID
    payload: {
        storeId: string | undefined,
    }
}
const setStoreIdAction = (storeId?: string): ISetStoreId => {
    return {
        type: APPLICATION_ACTIONS.SET_STORE_ID,
        payload: {
            storeId,
        },
    }
}

interface ISetClientId {
    type: typeof APPLICATION_ACTIONS.SET_CLIENT_ID
    payload: {
        clientId: string | undefined,
    }
}
const setClientIdAction = (clientId?: string): ISetClientId => {
    return {
        type: APPLICATION_ACTIONS.SET_CLIENT_ID,
        payload: {
            clientId,
        },
    }
}

interface IFetchDashboard {
    type: typeof APPLICATION_ACTIONS.FETCH_DASHBOARD_DATA
    payload: {
        fromDate: Date;
        toDate: Date;
    }
}
const fetchDashboardDataAction = (fromDate: Date, toDate: Date): IFetchDashboard => {
    return {
        type: APPLICATION_ACTIONS.FETCH_DASHBOARD_DATA,
        payload: {
            fromDate,
            toDate,
        },
    }
}

interface ISetDashboard {
    type: typeof APPLICATION_ACTIONS.SET_DASHBOARD_DATA
    payload: {
        dashboard: IDashboard,
    }
}
const setDashboardDataAction = (dashboard: IDashboard): ISetDashboard => {
    return {
        type: APPLICATION_ACTIONS.SET_DASHBOARD_DATA,
        payload: {
            dashboard,
        },
    }
}

export type IAllApplicationActions =
    | ISetLoadingState
    | ISetStoreId
    | ISetClientId
    | IToggleSideMenu
    | ISetDashboard
    | IFetchDashboard
    | ISetNotification;

export type {
    ISetLoadingState,
    ISetStoreId,
    ISetClientId,
    IToggleSideMenu,
    ISetDashboard,
    ISetNotification,
    IFetchDashboard,
}

export {
    setNotificationAction,
    setLoadingStateAction,
    setStoreIdAction,
    setClientIdAction,
    toggleSideMenuAction,
    setDashboardDataAction,
    fetchDashboardDataAction,
    APPLICATION_ACTIONS,
};


