import { APPLICATION_ACTIONS, IAllApplicationActions } from "../actions/ApplicationActions";
import { INotification, IDashboard } from "../types/General";

interface INotificationData {
    notification: null | INotification;
    loading: boolean;
    storeId: string | undefined;
    clientId: string | undefined;
    showSideMenu: boolean;
    dashboard: null | IDashboard;
}

const defaultState = {
    notification: null,
    loading: false,
    storeId: undefined,
    clientId: undefined,
    showSideMenu: false,
    dashboard: null,
};

export default (state: INotificationData = defaultState, action: IAllApplicationActions) => {
    switch (action.type) {
        case APPLICATION_ACTIONS.SET_NOTIFICATION:
            return { ...state, notification: action.payload.notification }

        case APPLICATION_ACTIONS.SET_LOADING_STATE:
            return { ...state, loading: action.payload.loading }

        case APPLICATION_ACTIONS.SET_CLIENT_ID:
            return { ...state, clientId: action.payload.clientId }

        case APPLICATION_ACTIONS.SET_STORE_ID:
            return { ...state, storeId: action.payload.storeId }

        case APPLICATION_ACTIONS.TOGGLE_SIDE_MENU:
            return { ...state, showSideMenu: !state.showSideMenu }

        case APPLICATION_ACTIONS.SET_DASHBOARD_DATA:
            return { ...state, dashboard: action.payload.dashboard }

        default:
            return state;
    }
};
