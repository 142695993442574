import styled from "@emotion/styled";
import axios from "axios";
import * as React from "react";
import { useEffect } from "react";
import { PDFViewer } from "react-view-pdf";
import { Loader, Button } from "rsuite";
import { useSetUrlWithContextParams } from "../../lib/hooks/useSetUrlWithContextParams";

const PDFWrap = styled("div")({
    minHeight: 500,
});

interface IProps {
    url: string;
    onError?: (errored: boolean) => void;
}

const ViewPDF: React.FC<IProps> = ({ url: previewUrl, onError }) => {
    const [filePath, setFilePath] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [reportError, setReportError] = React.useState<string>("");

    const url = useSetUrlWithContextParams(previewUrl);

    useEffect(() => {
        if (filePath === "") {
            setLoading(true);
            axios({
                url,
                method: "GET",
                responseType: "blob",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }).then(
                (response) => {
                    var file = URL.createObjectURL(response.data);
                    setFilePath(file);
                    setLoading(false);
                },
                (err) => {
                    const errorMessage = "Generate PDF error occurred: \n" + err;
                    setLoading(false);
                    setReportError(errorMessage);
                    if (onError) {
                        onError(true);
                    }
                }
            );
        }
    }, []);

    return (
        <PDFWrap>
            {loading && <Loader backdrop size="lg" center className="ma-5" />}
            {filePath !== "" && (
                <>
                    {!reportError && (
                        <>
                            <Button className="mb-2 btn btn-sm primary" onClick={() => window.open(filePath)}>
                                Print Version
                            </Button>
                            <PDFViewer
                                url={filePath}
                                onLoadError={(error) => {
                                    setReportError("Generate PDF error occurred: \n" + error);
                                }}
                            />
                        </>
                    )}
                </>
            )}
            {reportError !== "" && (
                <div className="alert alert-danger" role="alert">
                    {reportError}
                </div>
            )}
        </PDFWrap>
    );
};

export { ViewPDF };
